import React, { useEffect, useState } from 'react'
import { YMaps, Map, ZoomControl, Placemark } from 'react-yandex-maps';
import { ModalC } from "./modal";

export const Contacts = () => {
    const [isOpen, setModalState] = useState(null)
    const [phone, setPhone] = useState("")
    const [active, setActive] = useState(["markets", "childhood", "health"])
    const [placemarksList, setPlacemarksList] = useState([])
    const [allInfra, setAllInfra] = useState(true)
    useEffect(() => {
        try {
            setPhone(document.querySelector('.roistat-phone').innerHTML)
        } catch (e) {

        }
    }, [])

    const placeMarks = {
        markets: [
            {
                title: "Магнит",
                icon: "img/map_ico_magnit.svg",
                location: [45.0905399484707,38.9397634764404],
            },
            {
                title: "Магнит",
                icon: "img/map_ico_magnit.svg",
                location: [45.09756180143854,38.96100657183837],
            },
            {
                title: "Лента",
                icon: "img/map_ico_lenta.svg",
                location: [45.10728760765645,38.949934413024884],
            }
        ],
        childhood: [],
        health: [
            {
                title: "Аптека",
                icon: "img/map_ico.svg",
                location: [45.08765192121325,38.96585600573728],
            },
            {
                title: "Аптека",
                icon: "img/map_ico.svg",
                location: [45.10090523545699,38.965083529541],
            },
        ],
        sport: [],
        shopping: [],
        school: [],
    }

    const clickCheck = (type) => {
        if (active.includes(type)) {
            let slice = active.filter((active)=> active!=type)
            setActive([...slice])
        } else {
            setActive([...active, type])
        }
    }

    const allCheckClick = () => {
        if (Object.keys(placeMarks).length == active.length) {
            setActive(Object.keys(placeMarks).slice(0,3))
        } else {
            setActive([...Object.keys(placeMarks)])
        }
    }

    useEffect(()=>{
        let list = []
        active.forEach((type)=>{
            placeMarks[type].forEach((info)=>{
                list.push(info)
            })
        })
        
        setPlacemarksList([...list])
        if (Object.keys(placeMarks).length == active.length) {
            setAllInfra(true)
        } else {
            setAllInfra(false)
        }
    },[active])

    return (
        <React.Fragment>
            <div class="contact">
                <div class="wmain">
                    <div class="tm tar">контакты&nbsp;&nbsp;</div>
                    <div class="contact__plashka">
                        <ul class="contact__list">
                            <li>
                                <i><img src="img/cont_list_ico1.svg" /></i>
                                <span>
                                    г. Краснодар, <br />ул. Западный обход, <br />42/4к2
                                </span>
                            </li>
                            <li>
                                <i><img src="img/cont_list_ico2.svg" /></i>
                                <span>
                                    Пн-Пт: 9.00   - 18.00 <br />Сб-Вс: 10.00 - 16.00

                                </span>
                            </li>
                            <li>
                                <i><img src="img/cont_list_ico3.svg" /></i>
                                <span>
                                    <a href="tel:88612051457">8 861 205-14-57</a>
                                </span>
                            </li>
                        </ul>
                        <a class="btn_main" href="#" onClick={(e)=>{e.preventDefault(); setModalState(true)}}>
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0 26.0399C0 11.6333 10.3365 0 26.0077 0C41.6789 0 52 11.6333 52 26.0399C52 40.4464 41.6631 52 26.0077 52C10.3524 52 0 40.3672 0 26.0399ZM48.2641 26.0399C48.2672 21.6281 46.9634 17.3143 44.5173 13.6448C42.0712 9.9753 38.593 7.11494 34.5226 5.4252C30.4523 3.73545 25.9726 3.29227 21.6508 4.15218C17.3289 5.0121 13.3589 7.1365 10.2432 10.2561C7.12741 13.3756 5.00599 17.3505 4.14712 21.6776C3.28825 26.0047 3.73051 30.4895 5.4182 34.5648C7.10588 38.6401 9.96314 42.1226 13.6282 44.5717C17.2932 47.0207 21.6014 48.3261 26.0077 48.323C28.9458 48.3789 31.8651 47.8408 34.5907 46.7408C37.3162 45.6408 39.7921 44.0016 41.8701 41.9212C43.948 39.8407 45.5851 37.3617 46.6838 34.6329C47.7825 31.904 48.32 28.9815 48.2641 26.0399Z" fill="white"></path>
                                <path d="M38.339 13.9946V36.9278L34.1918 37.1657V21.079L15.8453 39.3844L12.9326 36.4681L31.2157 18.1472H15.2123L15.4971 14.0581L38.339 13.9946Z" fill="white"></path>
                            </svg>
                            <span>остались вопросы?</span>
                        </a>
                    </div>
                </div>
                <div class="contact__map">
                    <div id="map" style={{ width: "100%", height: "100%" }}>
                        <YMaps >
                            <div>
                                <Map defaultState={{ center: [45.09452214493853, 38.93920557696532], zoom: 15, controls: [] }} style={{ width: "100%", height: "100%", position: "absolute" }}  >
                                    <ZoomControl options={{ float: 'left' }} />
                                    <Placemark geometry={[45.09386859751625,38.95150082308959]}
                                        options={{
                                            iconLayout: 'default#image',
                                            hideIconOnBalloonOpen: false,
                                            iconImageSize: [129, 148],
                                            iconImageOffset: [-65, -148],
                                            cursor: 'default',
                                            iconShadow: true,
                                            balloonclose: true,
                                            iconImageHref: 'img/map_ico_home.svg',
                                        }}
                                    />
                                    {
                                        placemarksList.map((info)=>{
                                                return   <Placemark geometry={info.location}
                                                    options={{
                                                        iconLayout: 'default#image',
                                                        hideIconOnBalloonOpen: false,
                                                        iconImageSize: [52, 52],
                                                        iconImageOffset: [-26, -52],
                                                        cursor: 'default',
                                                        iconShadow: true,
                                                        balloonclose: true,
                                                        iconImageHref: info.icon,
                                                    }}
                                                />
                                            })
                                    
                                    }
                                </Map>
                            </div>
                        </YMaps>
                    </div>
                </div>
                <div class="wmain contact__bottom">
                    <div class="contact__map_info">
                        <label class="contact__map_all">
                            <input checked={allInfra} onClick={() => allCheckClick("markets")} type="checkbox" name="" />
                            <span>вся инфраструктура</span>
                        </label>
                        <div class="cmi_list">
                            <label class="cmi_check" >
                                <input checked={active.includes("markets")} onClick={() => clickCheck("markets")} type="checkbox" name="" />
                                <span>Супермаркеты</span>
                            </label>
                            <label class="cmi_check" >
                                <input checked={active.includes("childhood")} onClick={() => clickCheck("childhood")} type="checkbox" name="" />
                                <span>Детские сады</span>
                            </label>
                            <label class="cmi_check" >
                                <input checked={active.includes("health")} onClick={() => clickCheck("health")} type="checkbox" name="" />
                                <span>Здоровье</span>
                            </label>
                            <label class="cmi_check">
                                <input checked={active.includes("sport")}  onClick={() => clickCheck("sport")} type="checkbox" name="" />
                                <span>Спорт</span>
                            </label>
                            <label class="cmi_check" >
                                <input type="checkbox" checked={active.includes("shopping")} onClick={() => clickCheck("shopping")} name="" />
                                <span>Шоппинг</span>
                            </label>
                            <label class="cmi_check" >
                                <input type="checkbox" checked={active.includes("school")} onClick={() => clickCheck("school")} name="" />
                                <span>Школы</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"Задайте вопрос"}
                position={window.scrollY}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                        class: "in_name",
                    },
                    {
                        name: "email",
                        placeholder: "Ваш e-mail",
                        required: true,
                        type: "text",
                        class: "in_mail",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                        class: "in_phone",
                    },
                    {
                        name: "question",
                        placeholder: "Текст вопроса",
                        required: true,
                        type: "textarea",
                        class: "in_quest dop-info",
                    },
                ]}
                hidden={"Задать вопрос менеджеру"}
                btnTitle={"Задайте вопрос"}
                celtype={"getQuestion"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setModalState(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}

export default Contacts
