import React, { Suspense, useEffect, useContext } from "react"
import { BlocksContext } from "../context/blocksContext"
import About from "./about"
import Games from "./games"
import Header from "./header"
import { Menu } from "./menu"
import PopularPlans from "./popularPlans"
import Rest from "./rest"
import Sport from "./sport"
import { GetPodborTerraza } from "./getPodborTerraza"
import { Terraza } from "./terraza"
import InsideHouse from "./insideHouse"
import HodStr from "./hodStr"
import GetPodbor from "./getPodbor"
import HowToBuy from "./howToBuy"
import Company from "./company"
import Contacts from "./contacts"
import { Footer } from "./footer"


export const Loader = () => {
    /*const WillBe = React.lazy(() => import("./will_be"))
    const Nearly = React.lazy(() => import("./nearly"))
    const Plans = React.lazy(() => import("./plans"))
    const Gallery = React.lazy(() => import("./gallery"))
    const GetFlat = React.lazy(() => import("./get_flat"))
    const HowToBuy = React.lazy(() => import("./howToBuy"))
    const Material = React.lazy(() => import("./material"))
    const About = React.lazy(() => import("./about"))
    const HodStr = React.lazy(() => import("./hod_str"))
    const Docs = React.lazy(() => import("./docs"))
    const Contacts = React.lazy(() => import("./contacts"))
    const Footer = React.lazy(() => import("./footer"))*/

    const blocksImports = [
        <Menu />,
        <Header />,
        <GetPodborTerraza />,
        <About />,
        <PopularPlans />,
        <Rest />,
        <Sport />,
        <Games />,
        <Terraza />,
        <InsideHouse />,
        <HodStr />,
        <GetPodbor />,
        <HowToBuy />,
        <Company />,
        <Contacts />,
        <Footer />
    ]
    
    const loaded = useContext(BlocksContext)

    const LoadBlock = (block) => {
        return <Suspense fallback={<div>Загрузка...</div>}>{block}</Suspense>
    }

    const generateHtml = () => {
        let toDraw = []
        for (let i = 0; i < loaded.blocks; i++) {
            toDraw.push(LoadBlock(blocksImports[i]))
        }
        return (
            <div className="container_main">
                <div className="blocks" data={loaded.menuClick ? "true" : ""}>
                    {toDraw.map((block) =>
                        block
                    )}
                </div>
            </div>

        )
    }
    const handleScroll = (event) => {
        if (loaded.blocks < blocksImports.length) {
            loaded.setBlocks(blocksImports.length)
        } else {
            window.removeEventListener('scroll', handleScroll, true);
        }
    }
    useEffect(() => {
        if (loaded.blocks < blocksImports.length) {
            window.addEventListener('scroll', handleScroll, true);
        }
    })
    return generateHtml()

}