import { useEffect, useState } from "react"

const SelectInput = ({field}) => {
    const [selectedOption, setSelectedOption] = useState("")
    const toggleSelect = (e) => {
        e.preventDefault()
        if (e.currentTarget.classList.contains('selected_open')) {
            e.currentTarget.classList.remove('selected_open')
            return
        }
        e.currentTarget.classList.add('selected_open')
    }

    return (
        <div class="in_style in_style_c1">
            <div class="in_name">первоначальный взнос</div>
            <div class="select" onClick={toggleSelect}>
                <div class="selected">
                    {selectedOption}
                    <i>
                        <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M18 1.76866L16.4971 -6.56959e-08L8.98232 7.71534L1.52063 0.0270721L-7.73107e-08 1.76866L9.0442 11L18 1.76866Z" fill="#27423E"></path>
                        </svg>
                    </i>
                </div>
                <div class="select__list">
                    {field.options && field.options.map((option)=>{
                        return <div onClick={()=>{setSelectedOption(option)}}>{option}</div>
                    })}
                </div>
            </div>
        </div>
    )
}

export default SelectInput