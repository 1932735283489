import React, { useState } from "react"
import { useSendForm } from "../hooks/send-from.hook"
import ModalC from "./modal"
import { PhoneInput } from "./phoneInput"
import TooltipSlider from "./tooltipSlider"

export const GetPodbor = () => {
    const [success, setSuccess] = useState(false)
    const [rooms, setRooms] = useState(["1-комн"])
    const [otdelka, setOtdelka] = useState(true)
    const [floor, setFloor] = useState([2,8])
    const sendForm = useSendForm()

    const roomClick = (type) => {
        if (rooms.includes(type)) {
            let slice = rooms.filter((active)=> active!=type)
            setRooms([...slice])
        } else {
            setRooms([...rooms, type])
        }
    }
    return (
        <React.Fragment>
            <div className="podbor plr">
                <form className="wmain">
                    <div className="podbor__head">
                        <div className="tm">
                            подборка <br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;квартир
                        </div>
                        <div className="podbor__head_info">
                            <img src="img/decor_ico6.svg" />
                            <span>Получите подборку <br />свободных квартир <br />с планировками <br />и ценами</span>
                        </div>
                    </div>
                    <div className="podbor__content">
                        <div className="podbor__content_top">
                            <div className="pct__l"><img src="img/podbor_img.jpg" /></div>
                            <div className="pct__r">
                                <div className="pct__item">
                                    <div className="pct__item_title">выберите этаж: 2 — 12</div>
                                    {/* <div className="slid">
                                        <div style={{ left: "20%" }} className="slid_circle">2</div>
                                        <div style={{ left: "80%" }} className="slid_circle">8</div>
                                    </div> */}
                                    <TooltipSlider
											range
											min={2}
											max={8}
											value={floor}
											onChange={(val)=>{setFloor(val)}}
											tipFormatter={(value) => `${value}`}
											tipProps={{visible:true}}
										/>
                                </div>
                                

                                <div className="pct__item">
                                    <div className="pct__item_title">количество комнат</div>
                                    <div className="btn_select btn_select_flat">
                                        <div onClick={()=>roomClick("студия")} className={rooms.includes("студия")?"act":""}>студия</div>
                                        <div onClick={()=>roomClick("1-комн")} className={rooms.includes("1-комн")?"act":""}>1</div>
                                        <div onClick={()=>roomClick("2-комн")} className={rooms.includes("2-комн")?"act":""}>2</div>
                                        <div onClick={()=>roomClick("3-комн")} className={rooms.includes("3-комн")?"act":""}>3</div>
                                    </div>
                                </div>
                                <div className="pct__item">
                                    <div className="pct__item_title">отделка</div>
                                    <div className="btn_select btn_select_remont">
                                        <div onClick={()=>setOtdelka(true)} className={otdelka?"act":""}>с ремонтом</div>
                                        <div onClick={()=>setOtdelka(false)} className={otdelka?"":"act"}>без ремонта</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="podbor__content_bottom">
                            <label className="in_style">
                                <div className="in_name">имя</div>
                                <input type="text" name={"name"} req={"y"} />
                            </label>
                            <label className="in_style">
                                <div className="in_name">e-mail</div>
                                <input type="text" name={"email"} req={"y"} />
                            </label>
                            <label className="in_style">
                                <div className="in_name">телефон</div>
                                <PhoneInput />
                            </label>
                        </div>
                    </div>
                    <input className="text" type="hidden" value={"получить подборку квартир"} />
                    <input className="dop-info" type="hidden" name={"dop-info"} data={"Кол-во комнат"} value={rooms.join(', ')} />
                    <input className="dop-info" type="hidden" name={"dop-info"} data={"Отделка"} value={otdelka?"с отделкой":"без отделки"} />
                    <div className="podbor__bottom">
                        <img className="podbor__bottom_img" src="img/podbor_img2.jpg" />
                        <span className="podbor__bottom_text">
                            перезвоним и предоставим самые выгодные предложения на сегодня
                        </span>
                        <a className="btn_main btn_c1" href="#" template={"2"} celtype={"getPodbor"} onClick={(e) => { sendForm.sendForm(e, ()=>{setSuccess(true)}); }}>
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M51 26C51 39.8071 39.8071 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26Z" fill="white"></path>
                                <path d="M0 26.0399C0 11.6333 10.3365 0 26.0077 0C41.6789 0 52 11.6333 52 26.0399C52 40.4464 41.6631 52 26.0077 52C10.3524 52 0 40.3672 0 26.0399ZM48.2641 26.0399C48.2672 21.6281 46.9634 17.3143 44.5173 13.6448C42.0712 9.9753 38.593 7.11494 34.5226 5.4252C30.4523 3.73545 25.9726 3.29227 21.6508 4.15218C17.3289 5.0121 13.3589 7.1365 10.2432 10.2561C7.12741 13.3756 5.00599 17.3505 4.14712 21.6776C3.28825 26.0047 3.73051 30.4895 5.4182 34.5648C7.10588 38.6401 9.96314 42.1226 13.6282 44.5717C17.2932 47.0207 21.6014 48.3261 26.0077 48.323C28.9458 48.3789 31.8651 47.8408 34.5907 46.7408C37.3162 45.6408 39.7921 44.0016 41.8701 41.9212C43.948 39.8407 45.5851 37.3617 46.6838 34.6329C47.7825 31.904 48.32 28.9815 48.2641 26.0399Z" fill="#27423E"></path>
                                <path d="M38.339 13.9946V36.9278L34.1918 37.1657V21.079L15.8453 39.3844L12.9326 36.4681L31.2157 18.1472H15.2123L15.4971 14.0581L38.339 13.9946Z" fill="#27423E"></path>
                            </svg>
                            получить подборку <br />квартир
                        </a>
                    </div>
                </form>
            </div>
            {success ? <ModalC
                success={success}
                position={window.scrollY}
                close={() => { setSuccess(false) }}
            /> : <div></div>}
        </React.Fragment>

    )
}

export default GetPodbor