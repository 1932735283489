import { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper'

export const Games = () => {
    const [firstSwiper, setFirstSwiper] = useState(null);
    const [secondSwiper, setSecondSwiper] = useState(null);
    const [slide, setSlide] = useState(0)

    const slidesGal = [
        {
            img: "img/preim_img3.jpg",
            title: "ДЕТСКИЕ ПЛОЩАДКИ",
            desc: "При создании детских площадок мы учитываем все игровые сценарии. В таком пространстве ребёнок не просто двигается, но ещё и общается с друзьями, познаёт мир и взаимодействует с природой.",
        },
        {
            img: "img/preim_img3_1.jpg",
            title: "ДЕТСКИЕ ПЛОЩАДКИ",
            desc: "При создании детских площадок мы учитываем все игровые сценарии. В таком пространстве ребёнок не просто двигается, но ещё и общается с друзьями, познаёт мир и взаимодействует с природой.",
        },
    ]

    const nextSlide = () => {
        if (slide == slidesGal.length - 1) {
            setSlide(0)
            return
        }
        setSlide(slide + 1)
        return
    }

    const prevSlide = () => {
        if (slide == 0) {
            setSlide(slidesGal.length-1)
            return
        }
        setSlide(slide - 1)
        return
    }

    useEffect(()=>{
		if (secondSwiper && secondSwiper.realIndex !== slide) {
			secondSwiper.slideTo(slide, 300)
		}

		if (firstSwiper && firstSwiper.realIndex !== slide) {
			firstSwiper.slideTo(slide, 300)
			
		}
	},[slide])

    return (
        <div className="preim plr">
            <div className="wmain">
                <div className="tm tm2">
                    <span>
                    <i>&nbsp;&nbsp;игры</i><br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;на природе
                    </span>
                    <div className="tm__decor tm__decor1"><img src="img/decor_ico5.svg" /></div>
                </div>
            </div>
            <div className="preim__content">
                <div className="preim__l">
                    <Swiper
                        className=" swiper-container"
                        modules={[Navigation]}
                        spaceBetween={0}
                        speed={600}
                        slidesPerView={1}
                        onSwiper={swiper => setFirstSwiper(swiper)}
                        loop={false}
                        onSlideChange={(swiper) => {setSlide(swiper.realIndex)}}
                        
                    >
                        {slidesGal.map((slide)=> {
                            return <SwiperSlide><div className="preim__slide"><img src={slide.img} /></div></SwiperSlide>
                        })}
                    </Swiper>
                </div>
                <div className="preim__r">
                        <Swiper
                            className="preim__slider_r swiper-container"
                            modules={[Navigation]}
                            spaceBetween={0}
                            speed={600}
                            slidesPerView={1}
                            loop={false}
                            onSwiper={swiper => setSecondSwiper(swiper)}
                            onSlideChange={(swiper) => {setSlide(swiper.realIndex)}}
                            
                        >
                            {slidesGal.map((slide, index)=> {
                                return <SwiperSlide>
                                    <div className="preim__slider_content">
                                        <div className="psc__head">
                                            <i>.0{index+1}</i><span>{slide.title}</span>
                                        </div>
                                        <div className="psc__text">
                                        {slide.desc}
                                        </div>
                                    </div>
                                </SwiperSlide>
                            })}
                        </Swiper>
                    
                    <div className="slider_nav">
                        <div className="slider_btn slider_prev" onClick={()=>{prevSlide()}}>
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M44.1611 44.1377C34.1305 54.163 18.8033 55.0687 7.86648 44.1377C-3.07036 33.2067 -2.16417 17.8876 7.86647 7.8623C17.8971 -2.16302 33.193 -3.06874 44.1298 7.8623C55.0667 18.7933 54.1605 34.1436 44.1611 44.1377ZM10.4756 10.47C7.40473 13.5411 5.31392 17.4531 4.4674 21.7118C3.62089 25.9704 4.05663 30.3843 5.71969 34.3954C7.38275 38.4065 10.1983 41.8348 13.8106 44.2467C17.4228 46.6587 21.6696 47.9459 26.0138 47.9459C30.358 47.9459 34.6044 46.6587 38.2167 44.2467C41.8289 41.8348 44.6445 38.4065 46.3075 34.3954C47.9706 30.3843 48.4067 25.9704 47.5602 21.7118C46.7137 17.4531 44.6229 13.5411 41.552 10.47C39.5403 8.38003 37.127 6.71739 34.4571 5.5817C31.7872 4.446 28.9155 3.86076 26.0138 3.86076C23.1121 3.86076 20.2404 4.44601 17.5705 5.5817C14.9006 6.71739 12.4873 8.38004 10.4756 10.47Z" fill="white"></path>
                                <path d="M30.1308 41.5921L33.1932 38.9375L19.8346 25.6641L33.1463 12.4843L30.1308 9.79843L14.1474 25.7734L30.1308 41.5921Z" fill="white"></path>
                            </svg>
                        </div>
                        <div className="slider_nav__num">
                            <span>{slide+1}</span>&nbsp;/&nbsp;<span>{slidesGal.length}</span>
                        </div>
                        <div className="slider_btn slider_next" onClick={()=>{nextSlide()}}>
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.83887 7.8623C17.8695 -2.16302 33.1967 -3.06874 44.1335 7.8623C55.0704 18.7933 54.1642 34.1124 44.1335 44.1377C34.1029 54.163 18.807 55.0687 7.87015 44.1377C-3.06669 33.2067 -2.16052 17.8564 7.83887 7.8623ZM41.5244 41.53C44.5953 38.4589 46.6861 34.5469 47.5326 30.2882C48.3791 26.0296 47.9434 21.6157 46.2803 17.6046C44.6172 13.5935 41.8017 10.1652 38.1894 7.75327C34.5772 5.34134 30.3304 4.05405 25.9862 4.05405C21.642 4.05405 17.3956 5.34134 13.7833 7.75327C10.1711 10.1652 7.35554 13.5935 5.69247 17.6046C4.02941 21.6157 3.59329 26.0296 4.4398 30.2882C5.28632 34.5469 7.37713 38.4589 10.448 41.53C12.4597 43.62 14.873 45.2826 17.5429 46.4183C20.2128 47.554 23.0845 48.1392 25.9862 48.1392C28.8879 48.1392 31.7596 47.554 34.4295 46.4183C37.0994 45.2826 39.5127 43.62 41.5244 41.53Z" fill="white"></path>
                                <path d="M21.8692 10.4079L18.8068 13.0625L32.1654 26.3359L18.8537 39.5157L21.8692 42.2016L37.8526 26.2266L21.8692 10.4079Z" fill="white"></path>
                            </svg>
                        </div>
                    </div>
                </div>
            </div>
            </div>
    )
}

export default Games