import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useSendForm } from "../hooks/send-from.hook";
import { Form } from "./form";

export const ModalC = ({ title, position, hidden, flat, fields, celtype, btnTitle, success, personal, template, callback, close }) => {
  const sendForm = useSendForm()
  const [successShow, setSuccessShow] = useState(false)
  const successCallback = () => {
    setSuccessShow(true)
  }
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: position,
      });
    }, 0)
  }, [])

  if (success || successShow) {
    return <Modal isOpen={true} toggle={() => { setModalState(null) }}>
      <ModalBody>
        <div key="ok" className="pu_main">
          <div className="pu_table">
            <div className="pu_cell">
              <div className="pu_inner pu_good" id="popup_ok" style={{ display: "block" }}>
                <div className="closeform" onClick={(e) => { e.preventDefault(); close() }}><svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M22.8945 1.66618L2.34196 22.2188L0.894531 20.7713L21.4471 0.21875L22.8945 1.66618Z" fill="#AB0F14" /><path fill-rule="evenodd" clip-rule="evenodd" d="M2.34196 0.21875L22.8945 20.7713L21.4471 22.2188L0.894531 1.66618L2.34196 0.21875Z" fill="#AB0F14" /></svg></div>
                <div className="tm_main">
                  <div className="pu_title">
                    <span>Спасибо</span>
                    <div className="tm_table">
                      <div className="tm_cell">
                        <img data-src="img/text_spasibo.png" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pu_title_info">
                  В ближайшее время с вами свяжется наш специалист.
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  }

  if (flat) {
    const caseTitle = (classFlat) => {
      switch (classFlat) {
        case 0: return "Квартира-студия"
        case 1: return "Однокомнатная квартира"
        case 2: return "Двухкомнатная квартира"
        case 3: return "Трехкомнатная квартира"
      }
    }
    return (

      <Modal isOpen={true} toggle={() => { setModalState(null) }}>
        <ModalBody>
          <div key="ok" className="pu_main">
            <div className="pu_table">
              <div className="pu_cell">
                <div className="pu_inner pu_flat_main">
                  <div className="closeform">
                    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18 1.76866L16.4971 0L8.98232 7.71534L1.52063 0.0270722L5.4244e-07 1.76866L9.0442 11L18 1.76866Z" fill="#A2C9AE" />
                      <path d="M0 17.2313L1.50295 19L9.01768 11.2847L16.4794 18.9729L18 17.2313L8.95579 8L0 17.2313Z" fill="#A2C9AE" />
                    </svg>
                  </div>
                </div>
                <div className="pu_title">
                  {caseTitle(flat.rooms)}
                </div>
                <div className="pu_flat">
                  <div className="pu_flat__l">
                    <div className="pu_flat__info">
                      <span>Общая площадь <b>{flat.total_area} м<sup>2</sup></b></span>
                      <span>Жилая площадь <b>{flat.living_area} м<sup>2</sup></b></span>
                      {flat.bath_room_area > 0 ? <span>Санузел <b>{flat.bath_room_area} м<sup>2</sup></b></span> : ""}
                    </div>
                    <div class="pu_content">
                      <Form
                        fields={fields}
                        hidden={hidden + ". Кол-во комнат: " + flat.rooms + " Общая площадь: " + flat.total_area + " Жилая площадь: " + flat.living_area}
                        btnTitle={btnTitle}
                        celtype={celtype}
                        template={template}
                        callback={() => successCallback()}
                        className={"pu_flat_form_l"}
                        personal={true}
                      />
                    </div>
                  </div>
                  <div className="pu_flat__r">
                    <img src={process.env.REACT_APP_PLANS_URL + flat.photo} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal >
    )
  }

  return (
    <Modal isOpen={true} toggle={() => { setModalState(null) }}>
      <ModalBody>
        <div style={{ display: "block" }} className="pu_main plr">
          <div className="pu_table">
            <div className="pu_cell">
              <div className="pu_inner">
                <div class="closeform" onClick={(e) => { e.preventDefault(); close() }}><svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 1.76866L16.4971 0L8.98232 7.71534L1.52063 0.0270722L5.4244e-07 1.76866L9.0442 11L18 1.76866Z" fill="#A2C9AE" /><path d="M0 17.2313L1.50295 19L9.01768 11.2847L16.4794 18.9729L18 17.2313L8.95579 8L0 17.2313Z" fill="#A2C9AE" /></svg></div>
                <div className="pu_title">{title.split("<br/>").length > 0 ? <React.Fragment>{title.split("<br/>")[0]}<br />{title.split("<br/>")[1]}</React.Fragment> : title}</div>
                {celtype == "getIpot" ? <ul class="pu_bank">
                  <li><img src="img/bank_logo1.jpg" /></li>
                  <li><img src="img/bank_logo2.png" /></li>
                  <li><img src="img/bank_logo3.jpg" /></li>
                  <li><img src="img/bank_logo4.jpg" /></li>
                  <li><img src="img/bank_logo5.png" /></li>
                  <li><img src="img/bank_logo6.jpg" /></li>
                  <li><img src="img/bank_logo7.jpg" /></li>
                  <li><img src="img/bank_logo8.jpg" /></li>
                  <li><img src="img/bank_logo9.png" /></li>
                </ul> : <div></div>}
                <div class="pu_content">
                  <Form
                    fields={fields}
                    hidden={hidden}
                    btnTitle={btnTitle}
                    celtype={celtype}
                    template={template}
                    callback={() => successCallback()}
                    personal={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}

export default ModalC
