export const Footer = () => {
    return (
        <div class="footer plr">
            <div class="wmain">
                <div class="f_copy">
                    © 2022 ЖК "Sport vilage". Все права защищены.<br />
                    <a href="#">Политика конфиденциальности</a>
                </div>
            </div>
        </div>
    )
}