import React from "react"
import { Menu } from "./menu"
import { Link } from "react-router-dom";

export const Liters = () => {

    const sectionClick = (e) => {
        console.log(e.currentTarget.classList[1].split('-')[1])
    }
    
    return (
        <div className="container_main home_page">
            <Menu />
            <div className="home">
                <div className="home__img">
                    <img src="img/home.jpg" />
                        <div className="home_num home_num_2-1">2.1</div>
                        <div className="home_num home_num_2-2">2.2</div>
                        <div className="home_num home_num_2-3">2.3</div>
                        <div className="home_num home_num_2-4">2.4</div>
                        <div className="home_num home_num_2-5">2.5</div>
                        <div className="home_num home_num_2-6">2.6</div>
                        <div className="home_num home_num_2-7">2.7</div>
                        <div className="home_num home_num_2-8">2.8</div>


                        {/* 2.1 */}
                        <Link to={"/floor/2.1/2/1"}>
                            <svg className="home_one home_2_1-2" onClick={sectionClick} width="113" height="179" viewBox="0 0 113 179" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M21.0542 153.581L22.6533 154.384L24.7854 160.806L27.4505 167.495L32.7807 168.03L33.5802 170.17L41.5755 166.96L42.908 172.043L45.5731 171.241L48.7712 179L111.134 165.889V160.806L109.002 156.792H110.335L113 154.652V152.779H105.538L105.005 150.103L101.807 147.428L105.538 145.822L107.67 144.752L106.604 142.611H104.738H98.0755L91.4127 49.4993H93.5448L92.7453 42.0075L93.0118 44.148L90.8797 40.6697V39.3318L92.7453 42.0075L86.3491 33.4454L86.8821 27.0239L83.4175 22.2078L80.4859 22.4753L79.4198 23.0105V22.7429H78.0873L77.5542 17.3916L78.6203 17.1241V16.5889V16.3214L78.8868 16.8565L78.3538 10.1674L72.4906 2.40807L61.0307 3.47833L60.4976 2.9432L45.3066 4.28102V7.75934L40.5094 8.8296L40.2429 1.60538L39.1769 0L26.6509 1.60538V4.28102L6.66274 6.68909V9.09716L0 9.89985L9.32783 127.628" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.1/1/1"}>
                            <svg className="home_one home_2_1-1" onClick={sectionClick} width="86" height="172" viewBox="0 0 86 172" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23.2283 48.4625L30.1415 47.9023L30.418 45.3811L50.881 42.5798V40.0586L63.8778 38.3779L64.9839 40.0586L65.2605 47.342L70.2379 46.5016V42.8599L86 41.1792L84.0643 38.0977L83.7878 34.456L82.1286 31.3746V28.5733L81.5756 25.4919L77.9807 20.1694L73.2797 21.2899L72.7267 15.4072L73.5563 14.8469L72.7267 7.56352L67.1961 1.68078L41.7556 4.20195V7.28339L37.6077 8.12378L37.3312 1.40065L36.2251 0L24.6109 1.40065V3.92182L6.36013 6.16287V8.40391L0 9.2443L8.84888 124.658L21.2926 149.87V151.831L24.6109 157.993L26.8232 158.554L32.9068 172L23.2283 48.4625Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>


                        {/* 2.2 */}
                        <Link to={"/floor/2.2/2/1"}>
                            <svg className="home_one home_2_2-1" onClick={sectionClick} width="120" height="126" viewBox="0 0 120 126" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M117.448 91.1949H119.056L113.694 16.9979L111.012 10.2527L110.476 8.36403L82.3206 10.5225L82.5887 12.1413L78.8347 12.6809L77.4939 1.88865H79.1028L78.2984 0L72.3992 0.809422L71.5948 0L46.3891 2.15846L46.9254 14.8394H45.3165L45.5847 16.4582L38.6129 16.7281L38.0766 14.8394H33.25L33.5181 17.2677L31.373 17.5375L31.1048 14.8394L3.48589 17.5375L3.75403 20.7752L0 21.5846L9.38508 109.812L20.379 126L35.127 123.572L30.3004 60.167L36.1996 59.3576V57.1991L54.1653 55.0407L53.8972 52.6124L65.1593 51.2634L66.2318 52.6124L66.5 59.0878L70.7903 58.2784V55.3105L85.5383 53.6917L95.4597 52.8822L100.823 58.5482L101.359 65.2934L100.554 66.1028L101.359 71.7687L105.649 70.6895L109.403 75.8158L109.94 81.4818L111.548 84.4497L111.817 87.9572L112.621 89.0364L113.425 90.3854L114.23 91.7345L117.448 91.1949Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.2/2/1"}>
                            <svg className="home_one home_2_2-2" onClick={sectionClick} width="76" height="119" viewBox="0 0 76 119" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.9286 97.9356L20.9 105.869L21.1714 112.982L20.0857 113.255L20.6286 119L26.0571 118.453L76 112.708L70.3 17.2345L68.9429 15.5931L67.5857 16.1402L65.4143 14.2253V9.30115L64.8714 8.75402L47.7714 10.1218L48.0429 12.0368L36.1 12.8575L35.2857 3.82989L31.4857 0L6.78571 2.18851L7.6 15.5931L0 16.6874L2.98571 23.5264L8.14286 98.7563" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.2/3/1"}>
                            <svg className="home_one home_2_2-3" onClick={sectionClick} width="89" height="136" viewBox="0 0 89 136" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M43.6958 132.237L40.2108 32.7905L85.247 27.9526L85.7831 26.3399L89 28.4901L84.1747 24.996L84.7108 23.3834L75.8645 17.4704L72.9157 17.7391L72.3795 15.0514L67.8223 11.2885L64.6054 11.0198L64.3373 8.86957L52.8102 0L20.6416 3.49407L20.3735 5.91304L19.5693 6.18182L19.3012 5.10672L0 6.45059L0.536145 6.98814V12.0949L2.68072 13.7075L4.02108 13.17L5.36145 14.7826L10.991 108.585H11.259L13.6717 109.123L24.1265 107.51L24.9307 115.573L28.9518 118.798H30.8283L47.4488 136L43.6958 132.237Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.2/4/1"}>
                            <svg className="home_one home_2_2-4" onClick={sectionClick} width="87" height="143" viewBox="0 0 87 143" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M36.0276 140.322L34.1595 140.59V141.929L35.4939 143L39.2301 142.464L33.092 36.4195L33.8926 35.8839L35.7607 36.9551L61.6472 34.5449V29.9925H68.0521L73.3896 33.206L87 31.0637L83.5307 28.3858L83.2638 27.0468L76.0583 21.1554H74.7239L72.8558 19.5487L69.3865 20.0843L69.6534 16.603L59.5123 9.90824L57.6442 10.4438L56.5767 9.10487L56.8436 7.23034L49.9049 1.87453L48.3037 2.41011L45.3681 0L44.8344 1.60674L0 6.42697L3.46933 105.777L6.93865 109.258V113.543L9.60736 116.221L12.5429 116.757L13.0767 119.167L17.3466 118.631L20.2822 121.577V126.129L29.3558 135.234L31.2239 135.502L36.0276 140.322Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.2/5/1"}>
                            <svg className="home_one home_2_2-5" onClick={sectionClick} width="104" height="160" viewBox="0 0 104 160" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M54.2842 1.07383L40.8475 3.22148L35.4729 0H28.7545V4.56376L2.68734 6.97987L1.07494 5.90604L0 6.44295L6.18088 112.483L2.4186 113.289L4.83721 115.436L7.52455 115.168L12.093 120L12.3618 124.832L15.0491 128.322L17.7364 127.785L18.5426 130.738L24.7235 129.933V132.349L26.8734 134.497V139.329L48.6408 160L66.3773 158.658V155.705L104 150.604L99.4315 42.4161H102.119V35.1678L84.9199 23.0872H81.1576L81.4264 19.8658L73.3643 13.9597L72.0207 14.2282L69.3333 11.8121L65.8398 11.5436V8.5906L58.0465 2.14765L56.1654 2.68456" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>


                        {/* 2.3 */}
                        <Link to={"/floor/2.3/1/1"}>
                            <svg className="home_one home_2_3-1" onClick={sectionClick} width="100" height="145" viewBox="0 0 100 145" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M56.0724 40.6518L100 34.6964V31.3304L95.8656 29.2589V25.8929L91.2145 23.3036L91.4729 22.5268L84.7545 18.9018H82.6873L79.0698 16.8304L78.0362 17.0893V5.4375L68.2171 0L46.5116 2.58929L43.1525 0.517857L30.7494 1.29464L23.7726 2.07143V2.84821L0 5.4375L0.516796 11.6518L3.10078 13.2054V28.7411L4.39277 106.938L14.4703 114.188L13.9535 115.741L19.3798 119.625L21.7054 119.107L26.3566 122.732L29.7158 122.214V125.321L33.8501 128.17L34.1085 130.5L39.5349 134.643L42.6357 134.384L56.5891 145L56.0724 40.6518Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.3/2/1"}>
                            <svg className="home_one home_2_3-2" onClick={sectionClick} width="122" height="173" viewBox="0 0 122 173" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M46.8367 0.0224609V3.77674L0.0224609 10.0596L0.691784 120.878L16.1717 132.574L16.0753 134.483L22.8484 140.013L27.3326 140.203L32.1969 144.401L35.9181 143.828L36.4911 147.93L41.9283 151.935L41.8319 153.557L49.7481 159.661L52.8964 159.184L56.8077 161.57L56.9978 163.382L59.0002 165.098L60.6226 164.525L71.453 172.722L70.3127 50.8306L92.494 47.3077V41.0882L94.9813 40.0525L99.7492 42.6445V46.272L121.517 43.5782V40.4685L121.999 40.2233L115.942 37.097V32.8993L106.214 27.6521V24.8867L98.2012 21.7384H94.3863V8.86141L81.7959 2.08828L60.1433 4.85373V6.09322H58.6174L46.8367 0.0224609ZM115.686 32.902V36.9593L115.662 36.9455V33.2133L115.56 32.8332L115.686 32.902ZM94.1274 8.86417V9.10656L93.8933 8.96058L93.4526 8.50334L94.1274 8.86417Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.3/3/1"}>
                            <svg className="home_one home_2_3-3" onClick={sectionClick} width="140" height="193" viewBox="0 0 140 193" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.7017 138.276L10.5152 139.68L17.7217 143.706L18.4705 146.888L26.7069 152.693L30.4507 153.16L34.7108 156.775L36.7949 158.308L36.576 158.357L36.6301 158.403L41.5903 157.278L41.3092 162.239L57.7847 175.534L62.0908 175.815L86.3919 191.742L91.0791 190.561L86.719 191.959L86.8028 192.013L137.348 175.815L139.033 48.8596L138.565 48.2972V46.7047L137.162 45.9558V43.2413L128.458 38.7477V37.8123L118.348 31.5397H118.097L118.173 31.5856L118.091 31.5397H114.139L107.119 27.4192V13.0004L91.9549 5.04338L70.9868 8.32027L69.3027 7.00896H69.0513L69.3379 7.23337H67.8836H62.1881L52.0054 1.78541L62.1259 7.00896H62.3773L50.9107 1.08786L50.4376 1.32848V4.38097L29.0748 7.02519V3.46441L24.3957 0.920227L21.9548 1.93682V8.04178L0.186523 11.4998L1.30562 131.147M106.736 27.4138V12.9409L106.863 13.0085V27.4273L109.985 29.2604L106.736 27.4138ZM136.911 43.9794L131.048 40.2213L136.911 43.2467V43.9794ZM138.314 47.5186L137.048 46.7129V46.0342L138.314 46.7102V47.5186Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>

                        {/* 2.4 */}
                        <Link to={"/floor/2.4/1/1"}>
                            <svg className="home_one home_2_4-1" onClick={sectionClick} width="104" height="117" viewBox="0 0 104 117" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M92.9534 73.9375V67.9792L104 66.625L102.653 14.3542L98.8808 11.375L98.0725 6.5L68.7047 9.47917V1.08333L67.6269 0H62.7772V1.35417L58.7357 0L38.5285 1.89583V15.1667H36.6425L35.8342 12.1875L2.42487 15.1667L1.61658 18.9583L0 19.2292L1.34715 95.0625L5.3886 98.3125V104.542L21.285 117L96.1865 108.333L96.456 97.5L96.1865 76.6458L92.9534 73.9375Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.4/2/1"}>
                            <svg className="home_one home_2_4-2" onClick={sectionClick} width="61" height="73" viewBox="0 0 61 73" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M57.1704 69.9812L61 69.4323L60.4529 14.8195L59.0852 13.9962L57.1704 14.8195L53.0673 12.6241L52.5202 8.78195L51.6996 7.68421L45.6816 8.50752L36.1076 9.33083L34.7399 8.23308L27.3543 9.33083V3.0188L20.5157 0L0 1.64662V12.0752L0.820628 17.015L4.65022 19.7594L6.29148 73L39.9372 69.1579L44.0404 71.0789L57.1704 69.9812Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.4/3/1"}>
                            <svg className="home_one home_2_4-3" onClick={sectionClick} width="90" height="99" viewBox="0 0 90 99" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M80.625 15.4355L73.9286 12.5081L71.25 12.2419L61.3393 6.91936L57.0536 7.18548L42.8571 1.33065V0L4.28571 5.05645L0 5.58871L1.07143 6.65323L1.60714 10.379L5.35714 12.5081L7.5 11.7097L8.57143 12.5081L9.375 65.4677L14.7321 65.2016L25.1786 70.2581V81.9677L29.7321 84.0968H32.1429L38.8393 88.8871L43.6607 91.0161V95.0081L52.5 99V25.8145L55.4464 27.4113L74.7321 25.5484V21.2903L77.9464 20.7581L79.8214 21.5565V24.4839H85.9821V21.2903H90V19.6936L80.625 15.4355Z" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>
                        <Link to={"/floor/2.4/4/1"}>
                            <svg className="home_one home_2_4-4" onClick={sectionClick} width="101" height="121" viewBox="0 0 101 121" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.1618 80.932L7.76923 80.6667L28.9337 77.7478L41.7931 84.3816V96.8531L45.8117 96.5877L52.2414 99.2412L51.9735 102.425L62.1538 107.732L62.4218 111.447L79.8355 121L85.1936 119.939L87.3369 121L98.321 119.939L99.1247 31.3114H101V25.2083L85.9974 17.7785L77.4244 14.5943H74.7454L66.4403 11.6754L65.6366 10.0833L57.8674 7.42982H55.7241L53.313 5.83772L51.9735 4.24561H49.0265L41.7931 1.85746L39.6499 0.530702H37.7745H33.4881V3.71491H27.5942V0.796053L25.4509 0L22.2361 0.530702V4.77632L2.94695 6.63377L0 5.04167V78.0132" fill="#A4C6AF" fill-opacity="0.7" />
                            </svg>
                        </Link>

                        <div className="home__info plr">
                            <div className="wmain">
                                <div className="home__info_l">
                                    <div className="home__info_title">
                                        выберите <br />секцию
                                    </div>
                                    <div className="home__info_section">
                                        секция 2.3
                                        <span>
                                            <i>сдача:</i> 2 кв. 2024&nbsp;&nbsp;&nbsp;<i>блок:</i> 1
                                        </span>
                                    </div>
                                </div>
                                <div className="home__info_r"><img src="img/home_kompas.svg" /></div>
                            </div>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default Liters