import React, { useState } from "react"
import { Menu } from "./menu"
import { useParams, Link, useNavigate } from "react-router-dom";
import SelectInput from "./selectInput";


export const FloorPlan = () => {
    const navigate = useNavigate()
    
    let { section, block, floor } = useParams();

    const sectionClick = (section) => {
        navigate(`/floor/${section}/${1}/${1}`)
    }

    const blockClick = (block) => {
        navigate(`/floor/${section}/${block}/${floor}`)
    }

    const floorClick = (floor) => {
        navigate(`/floor/${section}/${block}/${floor}`)
    }

    let liters = {
        "2.1": {
            "1": {
                "1": {
                    img: "/img/liters/l-2-1_e-1_b-1.png",
                    svg: <svg width="1356" height="552" viewBox="0 0 1356 552" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.1/1/1/63455bfda82c570001758db4"}><path d="M365 227.965V0H440.832L463.376 22.3894L483.871 20.354H572V230L365 227.965Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/635798cd6010ac00012bd7d9"}><path d="M1035 228.965V1H1109.69L1131.89 21.354H1253V66.1327L1248.96 70.2035V192.327H1178.31V231L1150.06 228.965H1035Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/63455855a82c570001758dac"}><path d="M943.656 0L923.344 20.354L706 22.3894V230H1031V0H943.656Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/63454214a82c57000188a353"}><path d="M241.5 224H38.5V552H254.5V344.5H294V282.5H241.5V224Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/63455899a82c570001758dad"}><path d="M464 282.5H294.5V343H256.5V475.5L253 479V552H436.5V506.5L464 479V282.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/6345573fa82c570001758da8"}><path d="M0.5 118V209H43V229H362.5V0H286.5L265 21.5H137V83.5H43V118H0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/63454291a82c57000188a355"}><path d="M804.5 283H934V479.5H907.5L908.5 520.5H796.5V478.5L804.5 470.5V324.5V283Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/6345425ba82c57000188a354"}><path d="M1250.5 60V193H1174.5V285.5H1144.5V546.5H1355.5V473.5H1352L1351.5 60H1250.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/1/634558daa82c570001758dae"}><path d="M936 283H1140V548L961.5 546V498.5L936 470.5V324.5V283Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-1_e-2-9_b-1.png",
                    svg: <svg width="1352" height="527" viewBox="0 0 1352 527" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.1/1/2-9/635796876010ac00012bd7cf"}><path d="M255 346.389L295.792 344.343L293.752 285H461V489.63H452.842L434.485 506H381.455L365.139 489.63H257.04L255 346.389Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/63468299a82c5700016237f5"}><path d="M603.875 285V480.227H610V527H499.75V480.227H463V285H603.875Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/635799796010ac00012bd7db"}><path d="M42.1111 226H243.556V283.531H284.667L297 285.586V343.117L255.889 345.172L257.944 489H146.944V480.781H91.4444L38 462.289V232.164L42.1111 226Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/634661eda82c570001fc1bcb"}><path d="M244.719 230L236.562 227.955L38.7472 230V209.554H0V119.589H38.7472V82.7857H136.635V21.4464H265.112L285.506 1H363V230H244.719Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/635798656010ac00012bd7d5"}><path d="M365 228.965V1H440.832L463.376 23.3894L483.871 21.354H572V231L365 228.965Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/635797326010ac00012bd7d2"}><path d="M937.04 284H1141V488.63H1032.9L1014.54 505H961.515L945.198 488.63H935L937.04 286.046" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/635791e96010ac00012bd7c5"}><path d="M936 284V488.202H907.382V527H797V484.118L807.221 477.992V284H936Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/63579ade6010ac00012bd7e4"}><path d="M1352 61V479.828H1260.27V488H1140V295.952H1176.69V191.756H1248.04V61H1352Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/635798cd6010ac00012bd7d9"}><path d="M1035 230.938V0H1110.37L1132.78 20.6195H1255V65.9823L1250.93 70.1062V193.823H1179.63V233L1151.11 230.938H1035Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/63455855a82c570001758dac"}><path d="M945.656 0L925.344 20.354L708 22.3894V230H1033V0H945.656Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/1/2-9/6357924e6010ac00012bd7c6"}><path d="M698.881 21.2162L680.612 5H597.388L581.149 21.2162H571V230H707V23.2432L698.881 21.2162Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },
            "2": {
                "1": {
                    img: "/img/liters/l-2-1_e-1_b-2.png",
                    svg: <svg width="1347" height="548" viewBox="0 0 1347 548" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.1/2/1/63455b78a82c570001758db3"}><path d="M1062.5 1H987.5V229H1105.5L1110 224.5H1309V207H1347V118H1309V81H1213V22.5H1084L1062.5 1Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/63455916a82c570001758daf"}><path d="M1111 224.5V281.5H1055.5V342.5H1097V548H1309.5V224.5H1111Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/63455899a82c570001758dad"}><path d="M889.5 282V473L917 500.5V548H1094V342H1056V282H889.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/63579a926010ac00012bd7df"}><path d="M664.5 22V228H989V0.5H901.5L880 22H664.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/63454291a82c57000188a355"}><path d="M764.5 282V477H754V520.5H863.5V477H890V282H764.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/634542c2a82c57000188a356"}><path d="M400 1H324V228.5H530.5V22.5H421.5L400 1Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/634558daa82c570001758dae"}><path d="M423 281.5H216.5V548H395V500L412.5 482.5H423V281.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/63455b1aa82c570001758db2"}><path d="M227 21.5H100L108.5 30V192.5H180.5V227.5H324.5V0.5H248L227 21.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/1/6345595ea82c570001758db0"}><path d="M109.5 60.5H9.5V475H0.5V539L9.5 548H216.5V283.5H186L181 278.5V190.5H109.5V60.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-1_e-2-9_b-2.png",
                    svg: <svg width="1351" height="529" viewBox="0 0 1351 529" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.1/2/2-9/63579ddd6010ac00012bd7f2"}><path d="M0 61V481.789H88.4314V490H205V297.053H172.843V194.421H98.4804V61H0Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579c2a6010ac00012bd7ef"}><path d="M318 231V1H239.604L218.973 21.5357H89V72.875H97.2523V196.089H167.396V231H318Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579bab6010ac00012bd7e8"}><path d="M1094 347.091H1059.33L1057.29 286H888V491.673H898.198L914.515 510H967.545L985.901 491.673H1094V347.091Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579c5d6010ac00012bd7f0"}><path d="M1212.43 21.645H1082.06L1060.48 0H983.062L983 233H1102.66L1111.21 229.686L1312.16 231.343V211.935H1351V119.597H1312.16V82.3547L1212.47 82.3754L1212.43 21.645Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579e746010ac00012bd7f5"}><path d="M1307.36 228L1106.58 228.636V285.429H1065.34L1050.7 285.388L1050 350.078H1090.17V489.918L1107.28 490L1202.91 489.918L1202.87 481.427H1258.5L1311 464.3V228H1307.36Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/635791e96010ac00012bd7c5"}><path d="M747.167 286V473.866H739V529H849.25V490.202H886V286H747.167Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579a516010ac00012bd7de"}><path d="M658 232H907.371L917.591 229.956V213.602H983V1H897.151L876.711 21.4425H658" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/6357924e6010ac00012bd7c6"}><path d="M532.348 22.4364L548.812 6H635.246L651.71 22.4364H662V232H520V22.4364H532.348Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/635798656010ac00012bd7d5"}><path d="M317 2H392.832L415.376 22.5357H524V213.518H466.614V232H317V2Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/63579bd66010ac00012bd7e9"}><path d="M411.961 286H206V489.704H316.118L334.471 506H387.49L403.804 489.704L414 453.037V286" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.1/2/2-9/635796026010ac00012bd7cd"}><path d="M414 286V489.361H440.382V528H552V485.294L541.853 477.16V286" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },
        },


        "2.2": {
            "1": {
                //офисы
                "1": {
                    img: "/img/liters/l-2-2_e-1_b-1.png",
                    svg: <svg className="lit_pt2" width="962" height="556" viewBox="0 0 962 556" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/1/1/6357a2276010ac00012bd807"}><path d="M498 310.5H259V555.5H498V310.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/1/6357a2486010ac00012bd808"}><path d="M959.5 241.5H874.5V314H718V535H959.5V241.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/1/6357a2006010ac00012bd806"}><path d="M961.5 50.5H822V65.5H708.5V315.5H877.5V244.5H961.5V50.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/1/6357a26a6010ac00012bd80b"}><path d="M283 243H10.5V499.5H133.5V555H260V317.5H283V243Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/1/63579f936010ac00012bd7f8"}><path d="M293 0H10V131H0.5V153H10V246.5H281V317H408V246.5H469V65H293V0Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-2_e-2-9_b-1.png",
                    svg: <svg width="1020" height="609" viewBox="0 0 1020 609" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/1/2-9/63456b6ca82c57000140b8e2"}><path d="M334 0.5H45.5V43.5H0V151.5H45.5V246L310 248H347L522.5 246L520 15H334V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/2-9/63456a80a82c57000140b8de"}><path d="M881 15H762V315.5H888.5V535.5H1019.5V50H881V15Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/2-9/63456848a82c57000140b8d2"}><path d="M285.5 312V242H46V537L106.5 552H172.5V556H302V383H339.5V312H285.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/2-9/6345675ea82c57000140b8cd"}><path d="M339 312V383.5H300V557H413.5V593L470.5 608.5H543.5V312H339Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/1/2-9/63456977a82c57000140b8d8"}><path d="M645 312.5V315H542V609H617.5L676 594V558L786.5 557V537H891.5V312.5H645Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },
            "2": {
                "1": {
                    img: "/img/liters/l-2-2_e-1_b-2.png",
                    svg: <svg className="lit_pt1" width="852" height="518" viewBox="0 0 852 518" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/2/1/6357a10f6010ac00012bd800"}><path d="M851.5 15H524V268.5H722.5V458.5H851.5V15Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/2/1/6357a2916010ac00012bd80c"}><path d="M723.5 267H359V517.5H617V460.5H723.5V267Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/2/1/6357a0906010ac00012bd7fe"}><path d="M165 0.5H0.5V497.5H131.5V267H214V200H282V14H165V0.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-2_e-2-9_b-2.png",
                    svg: <svg width="851" height="596" viewBox="0 0 851 596" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/2/2-9/63456f98a82c57000140b8f0"}><path d="M160 36H0V523.5H127.5V300H216L217.5 235.5L283.5 234L279.5 1H160V36Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/2/2-9/634633a6a82c57000145f3c1"}><path d="M636.5 0.5H523.5V235H563V301H723V489H850.5V52H731V17L636.5 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/2/2-9/63456d5ca82c57000140b8e8"}><path d="M215 298.5L130 300.5V544.5H231V580L288.5 595.5H361V298.5H215Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/2/2-9/63456e38a82c57000140b8ec"}><path d="M361 299.5V596H434.5L500 581V545L616.5 544V487.5H720V299.5H361Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },

            "3": {
                "1": {
                    img: "/img/liters/l-2-2_e-1_b-3.png",
                    svg: <svg width="1401" height="622" viewBox="0 0 1401 622" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/3/1/6345611aa82c570001056b84"}><path d="M1295 22.5H1168.5V36.5L1167.5 308H1283V524.5H1400.5V36.5H1295V22.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/63456095a82c570001056b80"}><path d="M939.5 305.5V525H1285.5V305.5H939.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/63455d77a82c570001758db5"}><path d="M942 305H793V513.5L786.5 520V566.5H907.5V525H942V305Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/63455dcba82c570001056b7b"}><path d="M1022 0H940.5V246.5H1170V21.5H1044L1022 0Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/63467c0ca82c570001fc1c0d"}><path d="M835 23H698.5V25.5H704.5V247.5H939.5V0.5H857.5L835 23Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/6357a1926010ac00012bd803"}><path d="M491.5 23H363V247.5H379.5V309H523V247.5H595V45H491.5V23Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/6357a1726010ac00012bd802"}><path d="M124 68H0V286H242.5V308H380.5V243.5H364V24H124V68Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/3/1/6357a1556010ac00012bd801"}><path d="M241.5 282H77.5V621.5H463.5V480.5H331.5V305H241.5V282Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },

                "2-9": {
                    img: "/img/liters/l-2-2_e-2-9_b-3.png", // check
                    svg: <svg width="1402" height="626" viewBox="0 0 1402 626" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/3/2-9/63463851a82c57000145f3cf"}><path d="M1296.5 23.5H1170V37.5L1169 309H1284.5V525.5H1402V37.5H1296.5V23.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/63463665a82c57000145f3ca"}><path d="M941 306.5V526H1287V306.5H941Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/635796026010ac00012bd7cd"}><path d="M943.5 306H794.5V514.5L788 521V567.5H909V526H943.5V306Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/634634c3a82c57000145f3c3"}><path d="M334 305.5V484.5H457.5V501.5H541L565.5 488L576 484.5V305.5H334Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/634638b1a82c57000145f3d0"}><path d="M246 281.5H23V354L41.5 409.5H78V626H464V484.5H334V304.5H246V281.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/6346379fa82c57000145f3cd"}><path d="M367 23.5H0V285.5H246V248H367V23.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/634635b2a82c57000145f3c6"}><path d="M596 248V0H516.174L492.725 23.5H366V248H596Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/634636dca82c57000145f3cb"}><path d="M679 0.5H597.5V248H943V0.5H861.5L838.5 23.5H701L679 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/3/2-9/6346356ca82c57000145f3c5"}><path d="M1023.5 1H942V247.5H1171.5V22.5H1045.5L1023.5 1Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },

            "4": {
                "1": {
                    img: "/img/liters/l-2-2_e-1_b-4.png",
                    svg: <svg width="1413" height="578" viewBox="0 0 1413 578" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/4/1/63456430a82c5700019dedeb"}><path d="M941 2H855.904L831.955 25.3725H578.5V253H941V2Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/634562f5a82c5700014bad48"}><path d="M1303 24H1185V188.5H1133V253.5H1189.5V316.5H1411V38.5H1303V24Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/6345635da82c5700019dede9"}><path d="M1412.5 312H1071L1067.5 559.5H1140L1162.5 536H1410.5L1412.5 312Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/6345626ca82c5700014bad47"}><path d="M1068.5 312.5L822 311H820V559.5H1067.5L1068.5 312.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/63463979a82c57000145f3d2"}><path d="M599.5 311.5H449.5V578H610.5V534H599.5V311.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/634563cca82c5700019dedea"}><path d="M450 312H118V535L324.5 536V569L382.5 578H450V312Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/6345648fa82c5700019dedec"}><path d="M238.5 23L108.5 25V38H0.5V535.5H120V317L239.5 314V262L238.5 23Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/634564f2a82c5700019deded"}><path d="M366 24H235L233 252.5H470.5L472.5 1H388L366 24Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/1/63456201a82c5700014bad45"}><path d="M1023.5 1L942 0L941 76.5V253H1097L1135 251.5V189H1186.5V23.5L1047 24.5L1023.5 1Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-2_e-2-9_b-4.png",
                    svg: <svg width="1412" height="566" viewBox="0 0 1412 566" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/4/2-9/63463b38a82c57000145f3d8"}><path d="M1303.5 23.5H1185.5V182H1133.5V247H1190V310H1411.5V36.5H1303.5V23.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463b7ca82c57000145f3d9"}><path d="M1409.5 305H1068V547.5H1140.5L1164 524.5H1409.5V305Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63465d2aa82c570001fc1bbb"}><path d="M1068.5 305.5H821V547H1068.5V305.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463979a82c57000145f3d2"}><path d="M599.5 305.5H449.5V566H610.5V524H599.5V305.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463bbca82c57000145f3da"}><path d="M450 305H118V525H324.5V557L384 566H450V305Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463851a82c57000145f3cf"}><path d="M240.5 24H108.5V37H0.5V524H120V307L241.5 306V254L240.5 24Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/634564f2a82c5700019deded"}><path d="M366 23.5H235V247.5H472.5V0.5H389.5L366 23.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463aaea82c57000145f3d6"}><path d="M556 0.5H470.5V247.5H706.5V23.5H579.5L556 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463a6fa82c57000145f3d5"}><path d="M941.5 0.5H858L834.5 23.5H706V247.5H941.5V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/4/2-9/63463a07a82c57000145f3d4"}><path d="M1025 0H941L940 70.5V247H1096L1134 245.5V183H1185.5V23.5L1048 23L1025 0Z" fill="#A1C7AD" /></Link>
                        </svg>,
                }
            },

            "5": {
                "1": {
                    img: "/img/liters/l-2-2_e-1_b-5.png",
                    svg: <svg width="1431" height="582" viewBox="0 0 1431 582" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/5/1/63456650a82c570001aae7a5"}><path d="M1389 314H1046V563.5H1116L1138.5 541H1387V504.5H1431V403.5H1389V314Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/6345626ca82c5700014bad47"}><path d="M1046 564V314H807V564H1046Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/63467e15a82c570001531174"}><path d="M442 314H118.5V541.5H319V573.5L376.5 582H442V314Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/6345648fa82c5700019dedec"}><path d="M231 24H107.5V38H0V539.5H115.5L118 315.5H237.5L236 241.5H231V24Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/63467d23a82c57000153116c"}><path d="M1001 1.00011L920.5 1V255H1111V186H1161.5V25.0001H1025.5L1001 1.00011Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/63456199a82c570001056b85"}><path d="M360 24H230.5V255H460.5L462 0H382L360 24Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/634565d0a82c570001aae7a3"}><path d="M1276 24H1159V185.5H1108V255H1163V317L1389 314V48L1334 29.5H1276V24Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.2/5/1/63467e4aa82c570001afb211"}><path d="M816 23.5H574V255H923V0H839.5L816 23.5Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-2_e-2-9_b-5.png",
                    svg: <svg width="1438" height="569" viewBox="0 0 1438 569" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.2/5/2-9/63465d90a82c570001fc1bbc"}><path d="M1282.5 28.5V24H1167V183H1113V249.5H1168.5V312H1397V47.5L1341.5 28.5H1282.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63465e0ea82c570001fc1bbd"}><path d="M1050 307V551H1121.5L1144.5 527.5H1397V492H1437.5V394H1397V307H1050Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63465d2aa82c570001fc1bbb"}><path d="M806.5 306.5V550.5H1050.5V306.5H806.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63463979a82c57000145f3d2"}><path d="M591 307H441V569H599V524.5H591V307Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63463bbca82c57000145f3da"}><path d="M443 569V308H116.5V528.5H318V560.5L375.5 569H443Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/636cabbadcbed90001755230"}><path d="M109.5 36.5H0V527.5H115.5V309.5H233.5L235.5 290V255.5L230.5 250.5V23.5H109.5V36.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/634635b2a82c57000145f3c6"}><path d="M358.5 24.5H231V250H462.5V1H381.5L358.5 24.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63463aaea82c57000145f3d6"}><path d="M544.5 0.5H463V249.5H693.5V23.5H568.5L544.5 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63463a6fa82c57000145f3d5"}><path d="M820 23.5H693.5V249.5H924.5V0.5H843L820 23.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.2/5/2-9/63463a07a82c57000145f3d4"}><path d="M1006.5 0.5H924V249H1116V183H1165.5V23.5H1028.5L1006.5 0.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            }

        },

        "2.3": {
            "1": {
                "1": {
                    img: "/img/liters/l-2-3_e-1_b-1.png",
                    svg: <svg width="1544" height="583" viewBox="0 0 1544 583" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.3/1/1/634660baa82c570001fc1bc6"}><path d="M1153.5 1H1079V263H1302.5V25H1177.5L1153.5 1Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/63465e9ea82c570001fc1bc0"}><path d="M1003 0.5H1077.5V262.5H854V24.5H979L1003 0.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/63466390a82c570001fc1bcf"}><path d="M1440 24.5H1303V329.5H1430.5V509H1544V29.5H1440V24.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/634661eda82c570001fc1bcb"}><path d="M1425.5 324.5H1077V582.5H1165L1190.5 558H1326L1325 509.5H1425.5V324.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/63466123a82c570001fc1bc9"}><path d="M651.5 325H414.5V582H502.5L527.5 557H651.5V325Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/63466249a82c570001fc1bcc"}><path d="M278.5 324.5V327.5H46V348.5H0V452H49V506H157V557.5H303.5L327.5 582H415.5V324.5H278.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/6346617ea82c570001fc1bca"}><path d="M290.5 25H167V34H103.5L44.5 55V325H278V263.5H337V192H290.5V25Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/6346603ba82c570001fc1bc4"}><path d="M415 25H291V193.5H338.5V263H517V0.5H439.5L415 25Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.3/1/1/63465f7ea82c570001fc1bc2"}><path d="M595 0H521V258H697V187.5H743V86H752.5V24.5L619 25L595 0Z" fill="#A1C7AD"/></Link>
                        </svg>, 
                },
                "2-9": { //edit
                    img: "/img/liters/l-2-3_e-2-9_b-1.png",
                    svg: <svg xmlns="http://www.w3.org/2000/svg" width="1540" height="582" viewBox="0 0 1540 582" fill="none">
                        <Link to={"/flat/2.3/1/2-9/63466c9da82c570001fc1be0"}><path d="M1153.5 0.5H1079V262.5H1302.5V24.5H1177.5L1153.5 0.5Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466390a82c570001fc1bcf"}><path d="M1436 24H1299V329H1426.5V508.5H1540V29H1436V24Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/634661eda82c570001fc1bcb"}><path d="M1425.5 324H1077V582H1165L1190.5 557.5H1326L1325 509H1425.5V324Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466b91a82c570001fc1bde"}><path d="M1078.5 325H885V343.5L887.5 557H995.5V582H1078.5V325Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466cefa82c570001fc1be1"}><path d="M651.5 324.5H414.5V581.5H502.5L527.5 556.5H651.5V324.5Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466d83a82c570001fc1be7"}><path d="M278.5 324V327H46V348H0V451.5H49V505.5H157V557H303.5L327.5 581.5H415.5V324H278.5Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/6346617ea82c570001fc1bca"}><path d="M290.5 24.5H167V33.5H103.5L44.5 54.5V324.5H278V263H337V191.5H290.5V24.5Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466bfea82c570001fc1bdf"}><path d="M415 24.5H291V193H338.5V262.5H517V0H439.5L415 24.5Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/63466eeea82c570001fc1bec"}><path d="M592 0H515.5L516.5 262H851.5V24.5H616L592 0Z" fill="#A1C7AD"/></Link>
                        <Link to={"/flat/2.3/1/2-9/635bd654ab3e120001f0c41c"}><path d="M976.5 24.5H848V36H852.5V262.5H1079V0H1001.5L976.5 24.5Z" fill="#A1C7AD"/></Link>
                    </svg>,
                },
            },

            "2": {
                "1": {
                    img: "/img/liters/l-2-3_e-2-9_b-2_2.png",
                    svg: <svg width="1509" height="614" viewBox="0 0 1509 614" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.3/2/1/634667eda82c570001fc1bd8"}><path d="M543 1.5H467V265H647V190.5H692.5V25.5H567L543 1.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/634664c2a82c570001fc1bd1"}><path d="M483.5 326.5H226.5V565.5H483.5V326.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/6346668aa82c570001fc1bd4"}><path d="M1508.5 513.5V29.5H1392V25H1255.5V262.5H1251.5V332H1267.5V513.5H1508.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/634660baa82c570001fc1bc6"}><path d="M1108.5 0.5H1031.5V265.5H1259.5V24.5H1133L1108.5 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/63465e9ea82c570001fc1bc0"}><path d="M1031.5 0.5H956L930 25.5H805V265H1031.5V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/63466e28a82c570001fc1be8"}><path d="M465.5 0.5H388L363.5 24.5H237V265.5H465.5V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/6346661da82c570001fc1bd3"}><path d="M101 29.5H0V514H226.5V327.5H241V24.5H101V29.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/1/63466521a82c570001fc1bd2"}><path d="M923.5 325L867 328.5V564.5H971.5V599L1002.5 614H1127.5L1159.5 599V564.5H1267.5V325H923.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-3_e-2-9_b-2.png",
                    svg: <svg width="1512" height="617" viewBox="0 0 1512 617" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.3/2/2-9/63467185a82c570001fc1bf0"}><path d="M1511.5 513.5V29.5H1395V25H1258.5V262.5H1254.5V332H1270.5V513.5H1511.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63466c9da82c570001fc1be0"}><path d="M1108.5 0.5H1031.5V265.5H1259.5V24.5H1133L1108.5 0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63466e7da82c570001fc1beb"}><path d="M1031.5 0.5H956L930 25.5H805V265H1031.5V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63466eeea82c570001fc1bec"}><path d="M805.5 24.5H567L542 0.5H464.5V265H805.5V24.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63466c9da82c570001fc1be0"}><path d="M465.5 0.5H388L363.5 24.5H237V265.5H465.5V0.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63467124a82c570001fc1bef"}><path d="M101 29.5H0V514H226.5V327.5H241V24.5H101V29.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/6346707ca82c570001fc1bee"}><path d="M227 327.5V566.5H470.5V602L501.5 616.5H610.5L640 602V559H629.5V327.5H227Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/2/2-9/63466fa6a82c570001fc1bed"}><path d="M923.5 328L867 331.5V567.5H971.5V602L1002.5 617H1127.5L1159.5 602V567.5H1267.5V328H923.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },

            "3": {
                "1": {
                    img: "/img/liters/l-2-3_e-2-9_b-3_2.png",
                    svg: <svg width="1563" height="602" viewBox="0 0 1563 602" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.3/3/1/6346690ca82c570001fc1bd9"}><path d="M1144 335H907V576.048H1031.24L1055.69 600H1144V335Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/63466ab4a82c570001fc1bdc"}><path d="M1513.06 335H1144V602H1233.39L1258.36 576.376H1405.69V523.079H1516.56V467.731H1563V359.086H1513.06V335Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/63466a52a82c570001fc1bdb"}><path d="M472 336H112V525.213H216.746V576.077H356.576L382 601H472V336Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/63466b0aa82c570001fc1bdd"}><path d="M102.71 31.102H0V526H115.737V338.245H241.495L247.5 332.633V35.1837L241.495 26H102.71V31.102Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/63466471a82c570001fc1bd0"}><path d="M371 25L244.5 26V272.5H358.25L472 266.5V0.5H395.5L371 25Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/634667eda82c570001fc1bd8"}><path d="M1118.5 1H1042.5V271L1222.5 272.5V197.5H1271V27L1142.5 25.5L1118.5 1Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/634667eda82c570001fc1bd8"}><path d="M548.5 1H471V273H615V192.5H706V25H572.5L548.5 1Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/634669e8a82c570001fc1bda"}><path d="M1394.5 26H1267V201.442H1223V273.773H1282.5V342H1519V56.7792L1457.5 36.2597H1394.5V26Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/1/63466721a82c570001fc1bd5"}><path d="M938 26.5H805.5V32H812V196.5H858V272.5H1040.5V1H963.5L938 26.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-3_e-2-9_b-3.png",
                    svg: <svg width="1557" height="590" viewBox="0 0 1557 590" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.3/3/2-9/635b4899ab3e120001c75b10"}><path d="M1388.5 24.5H1261V195.5H1217V266H1276.5V332.5H1513V54.5L1451.5 34.5H1388.5V24.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/63466cefa82c570001fc1be1"}><path d="M1138 328H900.5V564.5H1025L1049.5 588H1138V328Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/6360ea53ab3e120001fc90aa"}><path d="M1507 328.5H1137.5V589H1227L1252 564H1399.5V512H1510.5V458H1557V352H1507V328.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/6360ebc5ab3e120001fc90b0"}><path d="M663 328.5H468.5V589.5H551V564.5H663V328.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/63466a52a82c570001fc1bdb"}><path d="M468.5 328.5H114.5V514.5H217.5V564.5H355L380 589H468.5V328.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/63466b0aa82c570001fc1bdd"}><path d="M102.5 30H0V515H115.5V331H241L243.5 325.5V34L241 25H102.5V30Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/63466e28a82c570001fc1be8"}><path d="M366 25L239.5 26V266.5H467V0.5H390.5L366 25Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/6360e811ab3e120001fc90a9"}><path d="M1113.5 1H1036.5V267H1216.5V193.5H1265V24.5H1137L1113.5 1Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/6360e6b6ab3e120001fc90a2"}><path d="M934 25H696V266H1037V0.5H959.5L934 25Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.3/3/2-9/635b48f0ab3e120001c75b11"}><path d="M545 0.5H467.5V266.5H698V24H568.5L545 0.5Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },
        },

        "2.4": {
            "1": {
                "1": {
                    img: "/img/liters/l-2-4_e-1_b-1.png",
                    svg: <svg className="lit_pt2" width="916" height="534" viewBox="0 0 916 534" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/1/1/6357a2486010ac00012bd808"}><path d="M831.5 301.5H683.5V514H912V234.5H831.5V301.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/1/1/63579f936010ac00012bd7f8"}><path d="M445.5 235V13H434V62.5H270.5V0H0V235H263V303H384V235H445.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/1/1/6357a0496010ac00012bd7fb"}><path d="M915.5 47.5H781.5V62H671.5V301.5H834.5V233.5H915.5V47.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/1/1/6357a26a6010ac00012bd80b"}><path d="M262.5 231.5H0V481H125.5V533H241.5V301H262.5V231.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/1/1/6357a2276010ac00012bd807"}><path d="M467 298.5H239.5V534H467V298.5Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-4_e-2-9_b-1.png",
                    svg: <svg width="991" height="583" viewBox="0 0 991 583" fill="none" xmlns="http:/1/www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/1/2-9/63456a80a82c57000140b8de"}><path d="M856.5 15H737.5V304H865V514H990.5V48.5H856.5V15Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/1/2-9/63467f22a82c570001afb214"}><path d="M863.5 297.5H527V582.5H598.5L653.5 568V534H763V513.5H863.5V297.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/1/2-9/6345675ea82c57000140b8cd"}><path d="M525 299.5H330.5V369.5H293.5V533.5H401.5V568L456 582H525V299.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/1/2-9/63456848a82c57000140b8d2"}><path d="M279 231.5H44.5V513.5L104.5 528.5H167V533H291.5V370.5H330V298H279V231.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/1/2-9/63456b6ca82c57000140b8e2"}><path d="M325 15V0H50.5V42.5H0V148H50.5V237.5H505.5V15H325Z" fill="#A1C7AD" /></Link>
                        </svg>,
                }
            },
            "2": {
                "1": {
                    img: "/img/liters/l-2-4_e-1_b-2.png",
                    svg: <svg className="lit_pt1" width="823" height="501" viewBox="0 0 823 501" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/2/2-9/6357a0b96010ac00012bd7ff"}><path d="M697 263H351V500.5H425.5L487 497L493 500.5H591L596.5 446H697V263Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/2/2-9/6357a2af6010ac00012bd80d"}><path d="M272.5 14.5H155V0H0V480.5H125.5V261.5H209.5V190.5H272.5V14.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/2/2-9/6357a2db6010ac00012bd80e"}><path d="M508 13.5V263H699V445H822.5V13.5H508Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-4_e-2-9_b-2.png",
                    svg: <svg width="827" height="573" viewBox="0 0 827 573" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/2/1/634633a6a82c57000145f3c1"}><path d="M619.5 1.5H510V224.5H548V290.5H703.5V468.5H827V50.5H712.5V16.5L619.5 1.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/2/1/63456e38a82c57000140b8ec"}><path d="M701.5 288H350.5V573H425L486.5 558.5V524H599.5V470.5H701.5V288Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/2/1/63456d5ca82c57000140b8e8"}><path d="M124.5 288V523H225V557L280 572H351.5V288H124.5Z" fill="#A1C7AD" /></Link>
                            <Link to={"/flat/2.4/2/1/63456f98a82c57000140b8f0"}><path d="M272.5 0H155V35H0V504H125.5V290H209.5V221.5H272.5V0Z" fill="#A1C7AD" /></Link>
                        </svg>,
                },
            },
            "3": {
                "1": {
                    img: "/img/liters/l-2-4_e-1_b-3.png",
                    svg: <svg width="1301" height="576" viewBox="0 0 1301 576" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/3/1/63467c5aa82c570001fc1c0e"}><path d="M869 283L870.5 486.5H1190.5V283H869Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/63455d77a82c570001758db5"}><path d="M870.5 283H733V475L728.5 479.5V525.5L843 524V482L870.5 483.5V283Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/63455dcba82c570001056b7b"}><path d="M947 0H871V229H1087L1085 22H971.5L947 0Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/6357a1556010ac00012bd801"}><path d="M306.5 281.5H223V262H17.5V326L34 377.5H68V576H428V445.5H306.5V281.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/63467c95a82c570001531169"}><path d="M1202 20H1084V285.5H1194.5V486H1300.5V34.5H1202V20Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/63467c0ca82c570001fc1c0d"}><path d="M775.5 22H656V230H873V0H797.5L775.5 22Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/6357a1926010ac00012bd803"}><path d="M456 20H337V227.5H353V283.5H486V227.5H554V42.5H456V20Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/1/6357a1726010ac00012bd802"}><path d="M118.5 64.5H0V262.5H224.5V285H353.5V226H340V21H118.5V64.5Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-4_e-2-9_b-3.png",
                    svg: <svg width="1304" height="575" viewBox="0 0 1304 575" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/3/2/634684ad91541e0001e04eb6"}><path d="M1195.5 24H1087V284.5H1195.5V483.5H1304V36H1195.5V24Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/634683cb91541e0001e04eb3"}><path d="M874 281.5V483.5H1194L1195.5 281.5H874Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/63468299a82c5700016237f5"}><path d="M874 282H736.5V474L732 478.5V521L846.5 519.5V481L874 482.5V282Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/6346356ca82c57000145f3c5"}><path d="M950.5 2H874.5V228H1090.5V23H971.5L950.5 2Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/6346840e91541e0001e04eb4"}><path d="M631 0L553 1.5L551 228.5H873.5L875.5 1.5H799L780 21H652L631 0Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/634635b2a82c57000145f3c6"}><path d="M458.5 22.5H340V228H555.5V2H479L458.5 22.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/634682d4a82c5700016237f6"}><path d="M535 281H309.5V443.5H432V461H503.5L525.5 448.5H535V281Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/634638b1a82c57000145f3d0"}><path d="M310 280.5H226.5V261H21V325L37.5 376.5H71.5V575H431.5V444.5H310V280.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/3/2/6346379fa82c57000145f3cd"}><path d="M341 22H0V260H229V228H341V22Z" fill="#A1C7AD"/></Link>
                        </svg>,
                }
            },
            "4": {
                "1": {
                    img: "/img/liters/l-2-4_e-1_b-4.png",
                    svg: <svg width="1433" height="558" viewBox="0 0 1433 558" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/4/1/63456650a82c570001aae7a5"}><path d="M1392.5 302H1045V542.5H1119.5L1144.5 517.5H1390.5V483.5H1432.5V386.5H1392.5V302Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/6345626ca82c5700014bad47"}><path d="M1046 301.5H807V540.5H1046V301.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/634563cca82c5700019dedea"}><path d="M440 301.5H116.5V517.5H317V549.5L374.5 558H440V301.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/6345648fa82c5700019dedec"}><path d="M231 23.0001H105V37.0001H0V517.5H115.5V302H235V242.5H231V23.0001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/63467d23a82c57000153116c"}><path d="M1005.5 0.000113547L920.5 0V247H1111V178.5H1161.5V24.0001H1030L1005.5 0.000113547Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/63456199a82c570001056b85"}><path d="M357.5 23.5001H232L230.5 246H460.5L462 1.00009H380L357.5 23.5001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/63467d91a82c570001531172"}><path d="M1279 23.5001H1164.5V180.5H1114.5V246H1168.5V307.5H1395.5V45.5001L1339 28.0001H1279V23.5001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/1/63467e4aa82c570001afb211"}><path d="M816 24.5001H574V246H923V1.00009H839.5L816 24.5001Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
                "2-9": {
                    img: "/img/liters/l-2-4_e-2-9_b-4.png",
                    svg: <svg width="1433" height="559" viewBox="0 0 1433 559" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <Link to={"/flat/2.4/4/2-9/63465d90a82c570001fc1bbc"}><path d="M1276 23.5001H1161.5V180.5H1111.5V244.5H1165.5V307.5H1392.5V45.5001L1336 28.0001H1276V23.5001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/634685aa91541e0001c814fd"}><path d="M1393 302H1045.5V542.5H1120L1145 517.5H1391V483.5H1433V386.5H1393V302Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63465d2aa82c570001fc1bbb"}><path d="M1046.5 301.5H807.5V540.5H1046.5V301.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63463979a82c57000145f3d2"}><path d="M588 301H440.5V558.5H600.5V504.5H588V301Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63463bbca82c57000145f3da"}><path d="M440.5 301.5H117V517.5H317.5V549.5L375 558H440.5V301.5Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/6345648fa82c5700019dedec"}><path d="M231.5 23.0001H105.5V37.0001H0.5V517.5H116V302H235.5V242.5H231.5V23.0001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63463aaea82c57000145f3d6"}><path d="M543.5 1.00009H461.5V244H692.5V23.5001H565.5L543.5 1.00009Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63463a6fa82c57000145f3d5"}><path d="M817.5 23.5001H692V244H922V1.00009H841L817.5 23.5001Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/63463a07a82c57000145f3d4"}><path d="M1006 0.000113547L921 0V244.5L1111.5 244.5V178.5H1162V24.0001H1030.5L1006 0.000113547Z" fill="#A1C7AD"/></Link>
                            <Link to={"/flat/2.4/4/2-9/634564f2a82c5700019deded"}><path d="M358 23.5001H232.5V244.5H462.5V1.00009H380.5L358 23.5001Z" fill="#A1C7AD"/></Link>
                        </svg>,
                },
            }
        },
    }

    const toggleSelect = (e) => {
        e.preventDefault()
        if (e.currentTarget.classList.contains('selected_open')) {
            e.currentTarget.classList.remove('selected_open')
            return
        }
        e.currentTarget.classList.add('selected_open')
    }

    console.log(liters[section][block][floor])
    return (
        <div className="container_main home_page container_main_inner">
            <Menu />
            <div class="home home_flat">
                <div class="home_flat__content plr">
                    <div class="hfc__head">
                        <div class="wmain">
                            <div class="hfc__head_l">
                                <Link to={"/liters"}>
                                    <div class="hfc__back">
                                        <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000489166 10.23L10.2305 -2.11215e-06L12.2305 1.75L5.03052 8.90014L34.3906 8.90015L34.3906 11.53L5.05054 11.53L12.2305 18.6501L10.2305 20.3501L0.000489166 10.23Z" fill="#27423E" />
                                        </svg>
                                        <span>к выбору секции</span>
                                        <span>назад</span>
                                    </div>
                                </Link>
                            </div>
                            <div class="hfc__head_r">
                                <div class="hfc__street">
                                    ул. Западный обход
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="home_flat__inner">
                        <div style={{ display: "block" }} class="home_flat__inner_img hfii_27">
                            <img src={liters[section][block][floor].img} />
                            {liters[section][block][floor].svg}
                        </div>
                    </div>
                </div>
                <div class="falt__info plr">
                    <div class="wmain">
                        <div class="falt__info_l">
                            <div class="fil__item">
                                <div className="select" onClick={toggleSelect}>
                                    <div className="selected">
                                        {section}
                                        <i>
                                            <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 1.76866L16.4971 -6.56959e-08L8.98232 7.71534L1.52063 0.0270721L-7.73107e-08 1.76866L9.0442 11L18 1.76866Z" fill="#27423E" />
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="select__list">
                                        <div>Литер</div>
                                        {Object.keys(liters).map((liter) => {
                                            return <div onClick={()=>{sectionClick(liter)}}>{liter}</div>
                                        })}

                                    </div>
                                </div>
                            </div>
                            <div class="fil__item">
                                <div className="select" onClick={toggleSelect}>
                                    <div className="selected">
                                        {block}
                                        <i>
                                            <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 1.76866L16.4971 -6.56959e-08L8.98232 7.71534L1.52063 0.0270721L-7.73107e-08 1.76866L9.0442 11L18 1.76866Z" fill="#27423E" />
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="select__list">
                                        <div>Блок</div>
                                        {Object.keys(liters[section]).map((block) => {
                                            return <div onClick={()=>{blockClick(block)}}>{block}</div>
                                        })}

                                    </div>
                                </div>
                            </div>
                            <div class="fil__item">
                                <div className="select" onClick={toggleSelect}>
                                    <div className="selected">
                                        {floor}
                                        <i>
                                            <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M18 1.76866L16.4971 -6.56959e-08L8.98232 7.71534L1.52063 0.0270721L-7.73107e-08 1.76866L9.0442 11L18 1.76866Z" fill="#27423E" />
                                            </svg>
                                        </i>
                                    </div>
                                    <div className="select__list">
                                        <div>Этаж</div>
                                        <div onClick={()=>{floorClick("1")}}>1</div>
                                        <div onClick={()=>{floorClick("2-9")}}>2-9</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="falt__info_r">
                            <div class="fil__item">
                                сдача<span>2024</span>
                            </div>
                            <div class="falt__info_kompas">
                                <img src="/img/home_kompas.svg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FloorPlan


