import { BlocksContext } from "./context/blocksContext"
import { useBlocks } from "./hooks/blocks.hook"
import { Loader } from './components/loader';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import FloorPlan from "./components/floorPlan";
import { Suspense } from "react";

import "./css/style.css";
import "./css/slick.css";
import "./css/swiper.css";
import Liters from "./components/liters";
import FlatDetail from "./components/flatDetail";

function App() {
  const { blocks, setBlocks, menuClick, setMenuClick } = useBlocks()
  return (
      <BlocksContext.Provider value={{ blocks, setBlocks, menuClick, setMenuClick }}>
        <Router>
          <Routes>
            <Route path="/flat/:section/:block/:floor/:id" element={<Suspense fallback={<div>Загрузка...</div>}><FlatDetail /></Suspense>} />
            <Route path="/floor/:section/:block/:floor" element={<Suspense fallback={<div>Загрузка...</div>}><FloorPlan /></Suspense>} />
            <Route path="/liters" element={<Suspense fallback={<div>Загрузка...</div>}><Liters /></Suspense>} />
            <Route path="*" element={<Suspense fallback={<div>Загрузка...</div>}><Loader /></Suspense>} />
          </Routes>
        </Router>
      </BlocksContext.Provider>
  );
}

export default App;