import React, { useState } from "react"
import ModalC from "./modal"

export const About = () => {
    const [isOpen, setModalState] = useState(null)
    const [aciveTab, setActiveTab] = useState(0)
    return (
        <React.Fragment>
            <div className="about plr">
                <div className="wmain">
                    <div className="about__l">
                        <div className="about_nav">
                            <div className={aciveTab===0?"act":""} onClick={(e)=>{setActiveTab(0)}}>о проекте</div>
                            <span></span>
                            <div className={aciveTab===1?"act":""} onClick={(e)=>{setActiveTab(1)}}>архитектура</div>
                        </div>
                        <div className="about__info" style={{ display: aciveTab!==1?"none":"" }}>
                            ЖК «SPORT VILLAGE» — микрорайон с собственной социальной, спор-тивной, развлекательной и коммерческой ин-фраструктурой. Комплекс подарит вам жизнь в гармонии с природой. При этом располо-жены дома рядом с ключевыми магистралями города в самом активно развивающемся рай-оне краевого мегаполиса.
                        </div>
                        <div style={{ display: aciveTab!==0?"none":"" }} className="about__info">
                            В проекте сдано 15 моно-литно-кирпичных домов, строится еще 8 но-вого формата. «Спокойная» архитектура ком-плекса сводит к минимуму визуальный шум и позволяет расслабиться во время прогулок. <br />
                            Sport Village будет оформлена в соответствии с популярным трендом на экологичность. Цветовая гамма домов — приятная для глаз, приближенная к природной, в бежевых, терракотовых, коричневых оттенках.
                        </div>
                    </div>
                    <div className="about__r">
                        <div className="about_img">
                            <img src="img/about_img.jpg" />
                        </div>
                    </div>
                </div>
                <div className="about_bottom wmain">
                    <a className="btn_main btn_c1" href="#" onClick={(e)=>{e.preventDefault(); setModalState(true)}}>
                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M51 26C51 39.8071 39.8071 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26Z" fill="white" />
                            <path d="M0 26.0399C0 11.6333 10.3365 0 26.0077 0C41.6789 0 52 11.6333 52 26.0399C52 40.4464 41.6631 52 26.0077 52C10.3524 52 0 40.3672 0 26.0399ZM48.2641 26.0399C48.2672 21.6281 46.9634 17.3143 44.5173 13.6448C42.0712 9.9753 38.593 7.11494 34.5226 5.4252C30.4523 3.73545 25.9726 3.29227 21.6508 4.15218C17.3289 5.0121 13.3589 7.1365 10.2432 10.2561C7.12741 13.3756 5.00599 17.3505 4.14712 21.6776C3.28825 26.0047 3.73051 30.4895 5.4182 34.5648C7.10588 38.6401 9.96314 42.1226 13.6282 44.5717C17.2932 47.0207 21.6014 48.3261 26.0077 48.323C28.9458 48.3789 31.8652 47.8408 34.5907 46.7408C37.3162 45.6408 39.7921 44.0016 41.8701 41.9212C43.948 39.8407 45.5851 37.3617 46.6838 34.6329C47.7825 31.904 48.32 28.9815 48.2641 26.0399Z" fill="#27423E" />
                            <path d="M38.339 13.9946V36.9278L34.1918 37.1657V21.079L15.8453 39.3844L12.9326 36.4681L31.2157 18.1472H15.2123L15.4971 14.0581L38.339 13.9946Z" fill="#27423E" />
                        </svg>
                        <span>записаться <br />на экскурсию</span>
                    </a>
                    <div className="about_nav__info">
                        эко-район<br /> краснодара
                    </div>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"запишитесь на экскурсию"}
                position={window.scrollY}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                    },
                ]}
                hidden={"записаться на экскурсию"}
                btnTitle={"записаться<br/>на просмотр"}
                celtype={"getExcursion"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setModalState(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}

export default About