import React, { useState } from "react"
import ModalC from "./modal"

export const GetPodborTerraza = () => {
    const [isOpen, setModalState] = useState(null)
    return (
        <React.Fragment>

            <div className="head_terraca plr">
                <div className="wmain">
                    <div className="head_terraca__l">
                        <div className="tm">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;квартиры <br />с террасами
                            <div className="tm__decor"><img src="img/decor_ico1.svg" /></div>
                        </div>
                    </div>
                    <div className="head_terraca__r">
                        <a className="btn_main" href="#" onClick={(e)=>{e.preventDefault(); setModalState(true)}}>
                            <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M52 26C52 40.3594 40.3594 52 26 52C11.6406 52 0 40.3594 0 26C0 11.6406 11.6406 0 26 0C40.3594 0 52 11.6406 52 26Z" fill="transparent" />
                                <path d="M0 26.0399C0 11.6333 10.3365 0 26.0077 0C41.6789 0 52 11.6333 52 26.0399C52 40.4464 41.6631 52 26.0077 52C10.3524 52 0 40.3672 0 26.0399ZM48.2641 26.0399C48.2672 21.6281 46.9634 17.3143 44.5173 13.6448C42.0712 9.9753 38.593 7.11494 34.5226 5.4252C30.4523 3.73545 25.9726 3.29227 21.6508 4.15218C17.3289 5.0121 13.3589 7.1365 10.2432 10.2561C7.12741 13.3756 5.00599 17.3505 4.14712 21.6776C3.28825 26.0047 3.73051 30.4895 5.4182 34.5648C7.10588 38.6401 9.96314 42.1226 13.6282 44.5717C17.2932 47.0207 21.6014 48.3261 26.0077 48.323C28.9458 48.3789 31.8652 47.8408 34.5907 46.7408C37.3162 45.6408 39.7921 44.0016 41.8701 41.9212C43.948 39.8407 45.5851 37.3617 46.6838 34.6329C47.7825 31.904 48.32 28.9815 48.2641 26.0399Z" fill="white" />
                                <path d="M38.339 13.9946V36.9278L34.1918 37.1657V21.079L15.8453 39.3844L12.9326 36.4681L31.2157 18.1472H15.2123L15.4971 14.0581L38.339 13.9946Z" fill="white" />
                            </svg>
                            <span>Получить подборку <br />квартир</span>
                        </a>
                        <div className="decor_ico"><img src="img/ico_star.svg" /></div>
                    </div>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"Получить подборку квартир"}
                position={window.scrollY}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                    },
                    {
                        name: "email",
                        placeholder: "Ваш e-mail",
                        required: true,
                        type: "text",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                    },
                ]}
                hidden={"Получить подборку квартир c терассами"}
                btnTitle={"Получить <br/> подборку"}
                celtype={"getPodborTerazza"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setModalState(false) }}
            /> : <div></div>}

        </React.Fragment>
    )
}

export default GetPodborTerraza