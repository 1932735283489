export const Company = () => {
    return (
        <div class="company plr">
            <div class="wmain">
                <div class="company_logo"><img src="img/d_logo.svg" /></div>
                <div class="tm tar">надежный</div>
                <div class="tm">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;застроищик</div>
                <div class="company__content">
                    <div class="company__l">
                        <ul class="company__list">
                            <li>
                                <i>27 лет</i>
                                <span>опыта работы на рынке недвижимости</span>
                            </li>
                            <li>
                                <i>2 256 000 м<sup>2</sup></i>
                                <span>построено жилья за 27 лет работы</span>
                            </li>
                            <li>
                                <i>515 500 м<sup>2</sup></i>
                                <span>жилья в стадии строительства</span>
                            </li>
                            <li>
                                <i>85 000</i>
                                <span>человек живут в построенных компанией домах</span>
                            </li>
                            <li>
                                <i>146</i>
                                <span>жилых домов построено <br />корпорацией за 27 лет работы</span>
                            </li>
                        </ul>
                    </div>
                    <div class="company__r">
                        <div class="company__text">
                            <div class="company__text_logo"><img src="img/d_logo_white.svg" /></div>
                            <p>
                                Корпорация «Девелопмент-Юг» работает на рынке недвижимости с 1995 года. За 27 лет сданы в эксплуатацию более 146 домов общей площадью 2 256 000 квадратных метров. Сегодня в домах корпорации проживают более 85 000 человек.
                            </p>
                            <p>
                                В 2021 году корпорация запустила два новых проекта в г. Вла-дивосток: ЖК «Каштановый двор» и ЖК «Футурист».
                            </p>
                            <p>
                                Получили премии: «Надежный застройщик России 2021», «Лучший региональный застройщик России 2021» и стали победителем премии Urban Awards 2021!
                            </p>
                            <p>
                                Совместно с Российским аукционным домом корпорация первая в стране провела два квартирных аукциона в элитном жилом комплексе Development-Plaza (г. Краснодар).
                            </p>
                            <p>
                                В 2021 г. Девелопмент-Юг ввела в эксплуатацию более 95 000 кв. м. жилья.
                            </p>
                            <p>
                                Сегодня компания реализует проекты уже в 8 городах страны: Краснодаре, Перми, Ростове-на-Дону, Батайске, Астрахани, Владивостоке, Нижнем Новгороде и Екатеринбурге.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company