import React, { useEffect, useState } from "react"
import { Menu } from "./menu"
import { useParams, Link } from "react-router-dom"
import ModalC from "./modal"

const FlatDetail = () => {
    let { section, block, floor, id } = useParams();
    const [isOpen, setIsOpen] = useState()
    const [flat, setFlat] = useState()

    useEffect(()=>{
        const headers = { 'Content-Type': 'application/json' }
        fetch(process.env.REACT_APP_BACKEND_URL + "/flats.php?ID="+id, headers)
            .then(res => res.json())
            .then((result) => {
                setFlat(result)
            })
    },[id])

    const getTilte = (rooms, name) => {
        if (name == "офис") {
            return <React.Fragment>Офис</React.Fragment>
        }
        switch (rooms) {
            case 0:
                return <React.Fragment>Квартира-студия</React.Fragment>
            default:
                return <React.Fragment>{rooms}-комнатная <br />квартира</React.Fragment>
        }
    }
    return (
        <React.Fragment>
            <Menu />
            <div className="container_main home_page container_main_inner">
                <div className="home home_flat home_flat_flat">
                    <div class="home_flat__content plr">
                        <div class="hfc__head">
                            <div class="wmain">
                                <div class="hfc__head_l">
                                <Link to={`/floor/${section}/${block}/${floor}`}>
                                    <div class="hfc__back">
                                        <svg width="35" height="21" viewBox="0 0 35 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0.000489166 10.23L10.2305 -2.11215e-06L12.2305 1.75L5.03052 8.90014L34.3906 8.90015L34.3906 11.53L5.05054 11.53L12.2305 18.6501L10.2305 20.3501L0.000489166 10.23Z" fill="#27423E" />
                                        </svg>
                                        <span>к выбору квартиры</span>
                                        <span>назад</span>
                                    </div>
                                    </Link>
                                </div>
                                <div class="hfc__head_r">
                                    <div class="hfc__street">
                                        ул. Западный обход
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="home_flat__inner home_flat__inner2">
                            <div class="home_flat__inner_content">
                                <div class="home_flat__inner_l">
                                    <img src={flat && "https://plans.leadactiv.ru/file-store/"+flat.photo} />
                                </div>
                                <div class="home_flat__inner_r">
                                    <div class="home_flat__title">{flat && getTilte(flat.rooms, flat.name)}</div>
                                    <ul class="home_flat__info">
                                        <li>
                                            площадь
                                            <span>{flat && flat.total_area} м<sup>2</sup></span>
                                        </li>
                                        <li>
                                            этаж
                                            <span>{floor}</span>
                                        </li>
                                        <li>
                                            Литер
                                            <span>{section}</span>
                                        </li>
                                        <li>
                                            блок
                                            <span>{block}</span>
                                        </li>
                                        <li>
                                            сдача
                                            <span>1 квартал 2024</span>
                                        </li>
                                    </ul>
                                    <a class="btn_main btn_c1" href="#" onClick={(e)=>{e.preventDefault(); setIsOpen(true)}}>
                                        <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M51 26C51 39.8071 39.8071 51 26 51C12.1929 51 1 39.8071 1 26C1 12.1929 12.1929 1 26 1C39.8071 1 51 12.1929 51 26Z" fill="white"></path>
                                            <path d="M0 26.0399C0 11.6333 10.3365 0 26.0077 0C41.6789 0 52 11.6333 52 26.0399C52 40.4464 41.6631 52 26.0077 52C10.3524 52 0 40.3672 0 26.0399ZM48.2641 26.0399C48.2672 21.6281 46.9634 17.3143 44.5173 13.6448C42.0712 9.9753 38.593 7.11494 34.5226 5.4252C30.4523 3.73545 25.9726 3.29227 21.6508 4.15218C17.3289 5.0121 13.3589 7.1365 10.2432 10.2561C7.12741 13.3756 5.00599 17.3505 4.14712 21.6776C3.28825 26.0047 3.73051 30.4895 5.4182 34.5648C7.10588 38.6401 9.96314 42.1226 13.6282 44.5717C17.2932 47.0207 21.6014 48.3261 26.0077 48.323C28.9458 48.3789 31.8652 47.8408 34.5907 46.7408C37.3162 45.6408 39.7921 44.0016 41.8701 41.9212C43.948 39.8407 45.5851 37.3617 46.6838 34.6329C47.7825 31.904 48.32 28.9815 48.2641 26.0399Z" fill="#27423E"></path>
                                            <path d="M38.339 13.9946V36.9278L34.1918 37.1657V21.079L15.8453 39.3844L12.9326 36.4681L31.2157 18.1472H15.2123L15.4971 14.0581L38.339 13.9946Z" fill="#27423E"></path>
                                        </svg>
                                        <span>оставить заявку</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="falt__info plr">
                        <div class="wmain">
                            <div class="falt__info_l">
                                <div class="fil__item">
                                    литер<span>{section}</span>
                                </div>
                                <div class="fil__item">
                                    блок<span>{block}</span>
                                </div>
                                <div class="fil__item">
                                    этаж<span>{floor}</span>
                                </div>
                            </div>
                            <div class="falt__info_r">
                                <div class="fil__item">
                                    сдача<span>2024</span>
                                </div>
                                <div class="falt__info_kompas">
                                    <img src="/img/home_kompas.svg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isOpen ? <ModalC
                title={"узнать стоимость"}
                position={window.scrollY}
                fields={[
                    {
                        name: "name",
                        placeholder: "Ваше имя",
                        required: false,
                        type: "text",
                    },
                    {
                        name: "phone",
                        placeholder: "Ваш телефон",
                        required: true,
                        type: "text",
                    },
                ]}
                hidden={flat?`Узнать стоимость квартиры. Литер: ${section}, блок: ${block}, этаж: ${floor}, кол-во комнат:${flat.rooms}, площадь: ${flat.total_area}`:`узнать стоимость квартиры. Литер: ${section}, блок: ${block}, этаж: ${floor}`}
                btnTitle={"узнать<br/> стоимость"}
                celtype={"getFlatCost"}
                template={2}
                callback={() => { console.log("success") }}
                personal={true}
                close={() => { setIsOpen(false) }}
            /> : <div></div>}
        </React.Fragment>
    )
}

export default FlatDetail