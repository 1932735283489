import React, { useState } from 'react'
import { Form } from './form';
import { useContext } from "react"
import { BlocksContext } from "../context/blocksContext"

export const Header = () => {
    const loaded = useContext(BlocksContext)
    const [show, setShow] = useState(false)
    return (<React.Fragment>
                <div className="header plr">
                    <div className="wmain">
                        <div className="header__l">
                            <div className="decor_ico"><img src="img/head_ico.svg" /></div>
                            <div className="header__l_img">
                                <img src="img/header_img1.jpg" />
                            </div>
                            <div className="header__l_info">
                                краснодар <br />Западный обход, 42/4 к2
                            </div>
                        </div>
                        <div className="header__r">
                            <div className="header__r_info">
                                ЖК «Sport vilage» <br />квартиры за 4,2 млн
                            </div>
                            <div className="header__r_img">
                                <img src="img/header_img2.jpg" />
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
    )
}

export default Header